import React from "react";
import GuideBase from "../../components/GuideBase";
import useLanguage from "../../components/hooks";

const listOfLinks = {
  fr: {
    1: "https://pubmed.ncbi.nlm.nih.gov/26818413/",
    2: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7172975/",
    3: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6145761/",
    4: "https://www.ameli.fr/assure/sante/themes/souffrance-psychique/etre-bien-dans-sa-tete",
    5: "https://www.ameli.fr/assure/sante/themes/troubles-anxieux-anxiete/vivre-troubles-anxieux-anxiete",
    6: "https://www.ameli.fr/val-de-marne/assure/sante/themes/depression-troubles-depressifs/vivre-depression",
  },
  en: {
    1: "https://pubmed.ncbi.nlm.nih.gov/26818413/",
    2: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7172975/",
    3: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6145761/",
    4: "https://www.ameli.fr/assure/sante/themes/souffrance-psychique/etre-bien-dans-sa-tete",
    5: "https://www.ameli.fr/assure/sante/themes/troubles-anxieux-anxiete/vivre-troubles-anxieux-anxiete",
    6: "https://www.ameli.fr/val-de-marne/assure/sante/themes/depression-troubles-depressifs/vivre-depression",
  },
};

const PsychologicalAdvices = () => {
  const [t, links] = useLanguage(listOfLinks);

  return (
    <GuideBase>
      <header>
        <p class="lg:w1/2 my-4 w-full text-3xl font-bold leading-relaxed text-gray-600">
          {t("psychological_advices.title")}
        </p>
      </header>
      <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
        {t("psychological_advices.intro")}
      </p>

      <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
        <img
          src="/img/guides/psycho_advices.jpg"
          class="object-cover object-center"
          alt="psycho_advices"
        />
      </p>
      <nav>
        <p
          id="contents"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("common.summary")}
        </p>

        <ul>
          <li>
            <a
              href="#risk"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("psychological_advices.summary.risk")}
            </a>
          </li>
          <li>
            <a
              href="#surgery"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("psychological_advices.summary.surgery")}
            </a>
          </li>
          <li>
            <a
              href="#sources"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("common.sources")}
            </a>
          </li>
        </ul>
      </nav>
      <article>
        <h2
          id="risk"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("psychological_advices.summary.risk")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("psychological_advices.risk.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("psychological_advices.risk.content.2")}</b>
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>

        <h2
          id="surgery"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("psychological_advices.summary.surgery")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("psychological_advices.surgery.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("psychological_advices.surgery.content.2")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("psychological_advices.surgery.content.3")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("psychological_advices.surgery.content.4")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>

        <h2
          id="sources"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("common.sources")}
        </h2>

        {[...Array(6)].map((_, i) => (
          <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            <a
              href={links[i + 1]}
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t(`psychological_advices.links.${i + 1}`)}
            </a>
          </p>
        ))}
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
      </article>
    </GuideBase>
  );
};

export default PsychologicalAdvices;
