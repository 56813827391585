import React from "react"

const TermsPro = () => {
  return (
    <div className="w-full lg:p-10 p-5">
      <body className="w-full antialiased relative">
        <header>
          <p className="lg:w1/2 w-full leading-relaxed text-gray-600 text-3xl my-4 font-bold lg:text-4xl">
            Conditions d’utilisation de SuMO® by Cortexx Medical Intelligence
          </p>
        </header>

        <nav>
          <p
            id="contents"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-2xl my-4 font-bold lg:text-2xl"
          >
            Sommaire
          </p>
          <ul>
            <li>
              <a
                href="#start"
                className="lg:w-3/4 w-full leading-snug text-blue-500 text-base lg:text-base font-bold"
              >
                Préambule
              </a>
            </li>
            <li>
              <a
                href="#access-conditions"
                className="lg:w-3/4 w-full leading-snug text-blue-500 text-base lg:text-base font-bold"
              >
                Conditions d’accès
              </a>
            </li>
            <li>
              <a
                href="#user-account"
                className="lg:w-3/4 w-full leading-snug text-blue-500 text-base lg:text-base font-bold"
              >
                Compte utilisateur
              </a>
            </li>
            <li>
              <a
                href="#services"
                className="lg:w-3/4 w-full leading-snug text-blue-500 text-base lg:text-base font-bold"
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="#restrictions"
                className="lg:w-3/4 w-full leading-snug text-blue-500 text-base lg:text-base font-bold"
              >
                Restrictions
              </a>
            </li>
            <li>
              <a
                href="#intellectual-property"
                className="lg:w-3/4 w-full leading-snug text-blue-500 text-base lg:text-base font-bold"
              >
                Propriété intellectuelle
              </a>
            </li>
            <li>
              <a
                href="#confidentiality"
                className="lg:w-3/4 w-full leading-snug text-blue-500 text-base lg:text-base font-bold"
              >
                Confidentialité
              </a>
            </li>
            <li>
              <a
                href="#tcus-time"
                className="lg:w-3/4 w-full leading-snug text-blue-500 text-base lg:text-base font-bold"
              >
                Durée des conditions générale d’utilisation
              </a>
            </li>
            <li>
              <a
                href="#data-privacy"
                className="lg:w-3/4 w-full leading-snug text-blue-500 text-base lg:text-base font-bold lg:text-sm"
              >
                Protection des données
              </a>
            </li>
            <li>
              <a
                href="#respect-tcus"
                className="lg:w-3/4 w-full leading-snug text-blue-500 text-base lg:text-base font-bold"
              >
                Non-respect des CGUs
              </a>
            </li>
            <li>
              <a
                href="#major-force"
                className="lg:w-3/4 w-full leading-snug text-blue-500 text-base lg:text-base font-bold"
              >
                Force majeure
              </a>
            </li>
            <li>
              <a
                href="#general-dispositions"
                className="lg:w-3/4 w-full leading-snug text-blue-500 text-base lg:text-base font-bold lg:text-sm"
              >
                Dispositions Générales
              </a>
            </li>
          </ul>
        </nav>

        <article>
          <p
            id="start"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-2xl my-4 font-bold lg:text-2xl"
          >
            Préambule
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            Cortexx Medical Intelligence, société anonyme au capital de 11 000
            €, immatriculée au Registre du Commerce et des Sociétés de Paris
            sous le numéro Paris B 881 797 328, dont le siège social est situé
            au 156 Boulevard Hausmann 75008 Paris France, dûment représentée par
            son Directeur Général M. Arthur André,&nbsp;
            <a
              href="mailto:dpo@cortexxmi.com"
              className="lg:w-3/4 w-full leading-snug text-blue-500 text-base lg:text-base font-bold lg:text-sm underline"
            >
              dpo@cortexxmi.com
            </a>
            &nbsp;(ci-après "Cortexx MI") vous concède, en tant que personne
            physique ou morale (l' "Utilisateur"), un accès à l'Application
            SUMO® Pro de Cortexx MI et toutes ses fonctionnalités ("SUMO® Pro"
            ou le "Produit") sous réserve de votre acceptation des présentes
            conditions générales.
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            Les présentes Conditions Générales d’Utilisations (les “CGU”)
            doivent être lues avant toute première utilisation de SUMO® Pro,
            afin de prendre connaissance de toute contre-indication, mise en
            garde et précaution(s) associée(s) à son utilisation et ses
            interactions avec d’autres produits.
          </p>
          <nav id="to-top" className="text-blue-500 text-base lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
          <p
            id="object-of-use"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-2xl my-4 font-bold lg:text-2xl"
          >
            Objet de l’utilisation de SUMO® Pro
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            En utilisant SUMO® Pro, l’Utilisateur reconnaît et accepte qu’il est
            lié par les termes des présentes CGU. Bien que l’Application SUMO®
            Pro Desktop soit accessible au travers de services de navigation en
            ligne, sous réserve des conditions décrites aux présentes, il est
            rappelé que l’application SUMO® Pro est principalement destinée aux
            médecins et professionnels de santé impliqués dans le suivi de leurs
            patients opérés de la colonne vertébrale. SUMO® Pro permet aux
            Utilisateurs (i) de visualiser et modifier l’auto-suivi des patients
            opérés et l’organisation personnalisée de leur épisode opératoire,
            certaines données relatives à leur santé et à leur activité
            quotidienne, et (ii) d’interagir avec les Utilisateurs de
            l’Application SUMO® pour patient (ci-après les « Services »). SUMO®
            Pro peut également être utilisée à des fins d’assistance diagnostic
            et pronostic par l’équipe médicale au sens de la réglementation
            applicable, permettant alors aux Utilisateurs ayant expressément
            consenti à ce dispositif de partager avec leurs patients, des
            données relatives à l’évaluation autonome des patients (en utilisant
            les données relatives à leur histoire de santé, histoire de la
            maladie, activité quotidienne) nécessaire aux actes d’assistance
            diagnostic et pronostic, d’informations et de personnalisation de
            l’épisode de soin en chirurgie lombaire proposés par SUMO® Pro. Dans
            la mesure où SUMO® Pro est principalement destinée aux patients
            allant être opéré en chirurgie du rachis, elle peut contenir des
            informations sur des sujets médicaux et liés à la santé. Toutefois,
            SUMO® Pro n’est pas un professionnel de santé et, à ce titre, ne
            pratique pas la médecine et ne fournit pas de services ou conseils
            thérapeutiques. Les informations contenues dans SUMO® Pro ne
            sauraient en aucun cas se substituer aux conseils d’un médecin ou
            d’un autre professionnel de santé. L’utilisation de SUMO® Pro ne se
            substitue pas au parcours médical établi par le médecin ou
            professionnel de santé ni à un acte médical interventionnel visant à
            modifier la prise en charge avant et après la chirurgie programmée.
            L’Utilisateur reconnaît et accepte que SUMO® Pro et les Services
            sont uniquement (i) réservés à un usage strictement privé, à
            l’exclusion de toute finalité professionnelle et (ii) destinés à des
            fins informatives et éducatives d’ordre général, à l’exception des
            informations de nature médicale échangées dans le cadre d’actes
            d’assistance diagnostic et pronostic, étant toutefois précisé que
            ces informations n’engagent que leur auteur et en aucun cas Cortexx
            MI.
          </p>

          <nav id="to-top" className="text-blue-500 text-base lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
          <p
            id="access-conditions"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-2xl my-4 font-bold lg:text-2xl"
          >
            Conditions d’accès à l’application
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            SUMO® Pro est une application web fournie par CORTEXX MEDICAL
            INTELLIGENCE à l’Utilisateur, pour le suivi des patients opérés en
            chirurgie du rachis et ce, pour la durée spécifiée au paragraphe 8
            ci-dessous et sous réserve des autres termes des présentes CGU. Le
            manuel d’utilisation de SUMO® Pro est disponible sur l’application
            web directement ou le site Internet de Cortexx MI
          </p>
          <a
            href="https://www.cortexxmi.com"
            className="lg:w-3/4 w-full leading-snug text-blue-500 text-base lg:text-base font-bold lg:text-sm underline"
          >
            https://www.cortexxmi.com
          </a>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            Avant toute première installation, l’Utilisateur doit s’assurer
            qu’il dispose d’un accès Internet dont les frais restent à sa
            charge. L’Utilisateur reconnaît en outre avoir vérifié que son
            ordinateur / sa tablette ne contient aucun virus ou contenu
            susceptible de menacer la sécurité du Produit. Il appartient à
            l’Utilisateur d’installer l’application sur un ordinateur ou une
            tablette, fonctionnant sur les navigateurs conformément aux
            conditions décrites sur les navigateurs sur lesquels elle est
            disponible.
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            L’application SUMO® Pro permet à l’Utilisateur : (i) De visualiser
            l’état de progression des patients utilisant SUMO® et complétant les
            données et les informations en répondant au(x) questionnaire(s)
            intégré(s) ; (ii) De modifier, intégrer des données personnelles et
            médicales complémentaires à celles intégrées par le patient ; (iii)
            D’accéder l’outil d’assistance diagnostic et pronostic et aux
            résultats proposés par SUMO® Pro ; (iv) De produire un rapport à
            partir au format PDF.
          </p>

          <nav id="to-top" className="text-blue-500 text-base lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
          <p
            id="user-account"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-2xl my-4 font-bold lg:text-2xl"
          >
            Compte utilisateur
          </p>
          <p className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-xl my-4 font-bold lg:text-base">
            Création, validation et utilisation du Compte Utilisateur
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            Pour accéder à et utiliser SUMO® Pro, chaque Utilisateur doit se
            connecter au Compte Utilisateur qu’il aura créé au préalable. La
            création d’un Compte Utilisateur s’effectue depuis la page d’accueil
            de SUMO® Pro en saisissant l’adresse de courrier électronique valide
            et un mot de passe de connexion. L’Utilisateur est invité à
            compléter les informations de son Compte Utilisateur en fournissant
            certaines informations personnelle le concernant relatives à : (i)
            son identité, incluant notamment son nom, son prénom, son numéro de
            téléphone; (ii) son numéro ADELI, sa spécialité médicale et le lieu
            d’exercice de son activité professionnelle. L’Utilisateur s’engage à
            fournir, lors de la création et de l’utilisation du Compte
            Utilisateur, des informations exactes, complètes et sincères.
            L’Utilisateur s’engage à ne pas créer ou utiliser d’autres comptes
            que celui initialement créé, que ce soit sous sa propre identité ou
            celle de tiers. Aucun mot de passe n’est associé au Compte
            Utilisateur. Afin de se connecter au Compte Utilisateur,
            l’Utilisateur est invité à saisir l’adresse électronique associée
            audit Compte Utilisateur, et à entrer son mot de passe.
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            L’Utilisateur reconnaît que tout accès et utilisation de SUMO® Pro
            et des Services via son Compte Utilisateur est effectué sous sa
            seule responsabilité, sauf à démontrer une utilisation frauduleuse
            ne résultant d’aucune faute ou négligence de sa part. A cet égard,
            l’Utilisateur s’engage à avertir CORTEX MEDICAL INTELLIGENCE dans
            les meilleurs délais de tout détournement ou de toute utilisation
            frauduleuse de son Compte Utilisateur à l’adresse électronique
            indiquée en préambule des présentes CGU.
          </p>
          <p
            id="modify-user-account"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-xl my-4 font-bold lg:text-base"
          >
            Modification du Compte Utilisateur
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            À tout moment, l’Utilisateur peut mettre à jour l’ensemble des
            informations de son Compte Utilisateur au sein son espace personnel
            dédié au sein de SUMO®, à l’exception de l’adresse électronique
            associée au Compte Utilisateur. Pour toute modification de cette
            adresse électronique, l’Utilisateur doit adresser sa demande à
            CORTEXX MEDICAL INTELLIGENCE à l’adresse électronique indiquée en
            préambule des présentes CGU. A ce titre, l’Utilisateur est seul
            responsable du préjudice qu’il pourrait subir en cas d’inexactitude
            des informations le concernant et CORTEXX MEDICAL INTELLIGENCE ne
            pourra, en aucune manière, être tenue responsable des informations
            communiquées par l’Utilisateur qui pourraient s’avérer inexactes,
            erronées ou frauduleuses.
          </p>
          <p className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-xl my-4 font-bold lg:text-base">
            Durée de validité du Compte Utilisateur
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            Sous réserve des dispositions ci-après, le Compte Utilisateur
            demeure valide pour une durée indéterminée à compter de sa date de
            création, l’Utilisateur pouvant accéder à et utiliser les Services
            durant cette période de validité.
          </p>
          <p className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-xl my-4 font-bold lg:text-base">
            Fin du Compte Utilisateur
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            L’Utilisateur peut clôturer son Compte Utilisateur à tout moment en
            cliquant sur le bouton prévu à cet effet au sein de son espace
            personnel dédié au sein de SUMO® Pro. Dans un tel cas, l’Utilisateur
            n’aura plus accès à SUMO® Pro et aux Services, en ce compris son
            Compte Utilisateur. En outre, en cas d’inactivité du compte pendant
            une durée de 24 mois, CORTEXX MEDICAL INTELLIGENCE se réserve la
            possibilité de clôturer le compte de l’Utilisateur, après avoir
            averti préalablement le professionnel de santé qui suit
            éventuellement l’Utilisateur. Enfin, en cas de manquement de
            l’Utilisateur, CORTEXX MEDICAL INTELLIGENCE se réserve le droit, en
            fonction de la gravité et de la durée du manquement constaté, à son
            unique discrétion, de suspendre ou de clôturer le Compte
            Utilisateur, dans les conditions de l’article{" "}
            <a
              href="#tcus-time"
              className="text-blue-500 text-base lg:text-base"
            >
              ci-dessous.
            </a>{" "}
            Dans tous les cas, l’Utilisateur est informé que la clôture de son
            Compte Utilisateur n’entraînera pas la suppression automatique des
            données à caractère personnel le concernant. Ces données seront
            archivées dans les conditions définies dans la Politique de
            confidentialité accessible sur ce lien :
          </p>
          <a
            href="https://cortexxmi.com/sumo/pro-privacy"
            className="lg:w-3/4 w-full leading-snug text-blue-500 text-base lg:text-base font-bold lg:text-sm underline"
          >
            https://cortexxmi.com/sumo/pro-privacy
          </a>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            Conformément au Règlement général sur la protection des données (dit
            « RGPD ») et à la loi Informatique et Libertés, l’Utilisateur est
            informé qu’il dispose de ses droits sur ses données également
            pendant toute la durée de l’archivage de celles-ci. Pour en savoir
            plus sur ses droits, il est invité à consulter la Politique de
            confidentialité accessible sur ce lien :
          </p>
          <a
            href="https://cortexxmi.com/sumo/pro-privacy"
            className="lg:w-3/4 w-full leading-snug text-blue-500 text-base lg:text-base font-bold lg:text-sm underline"
          >
            https://cortexxmi.com/sumo/pro-privacy
          </a>

          <nav id="to-top" className="text-blue-500 text-base lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
          <p
            id="services"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-2xl my-4 font-bold lg:text-2xl"
          >
            Description des services
          </p>
          <p className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-xl my-4 font-bold lg:text-base">
            Visualisation globale de l’épisode de chirurgie du rachis de ses
            patients
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            SUMO® Pro offre aux Utilisateurs des fonctionnalités permettant
            d’assurer une visualisation globale de leur épisode de chirurgie du
            rachis. Ainsi, SUMO® Pro permet à tout Utilisateur d’importer, de
            renseigner, de centraliser, d’organiser et de consulter au sein de
            son Compte Utilisateur, des données relatives à la santé de ses
            patients. L’ensemble de ces données (ci-après les « Données de Suivi
            ») peut être importé au sein du Compte Utilisateur de l’Utilisateur
            concerné (i) manuellement et directement par l’Utilisateur via les
            champs prévus à cet effet dans SUMO®Pro, (ii) via des formulaires
            guidés, via la capacité de charger des documents médicaux, sous
            réserve que le patient consente expressément à l’importation des
            données contenues dans ces documents médicaux vers son Compte
            Utilisateur. Aucune Donnée de Suivi ne saurait être importée sans le
            consentement du patient qui demeure libre, à tout moment, de mettre
            fin à l’importation au sein de son Compte Utilisateur SUMO® Pro des
            données contenues dans les documents médicaux (ii) ci-dessus.
            SUMO®Pro permet également aux Utilisateurs d’organiser et
            transmettre des guides et informations médicales fournies par
            SUMO®Pro et sous le contrôle de son appréciation médicale.
            L’Utilisateur qui souhaite utiliser ces informations ou guide pourra
            être alerté et notifié de leur mise à disposition selon les
            conditions et modalités paramétrées par lui au sein de SUMO® Pro.
          </p>
          <p className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-xl my-4 font-bold lg:text-base">
            Outil d’assistance diagnostic et pronostic médical
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            SUMO® permet également aux Utilisateurs qui y ont expressément
            consenti, en plus des fonctionnalités de visualisation de l’épisode
            de soin ci-dessus, de bénéficier d’un outil d’assistance diagnostic
            et pronostic médicale au sens de la réglementation applicable, et
            notamment de l’article R.6316-1 3° du Code de la santé publique,
            pratiqués par un médecin ou tout autre professionnel de santé. A ce
            titre, SUMO® Pro permet au médecin ou autre professionnel de santé
            (i) adhérent au programme SUMO® Pro et (ii) expressément autorisé
            par le patient utilisant SUMO®, de consulter les résultats du
            traitement autonome des données réalisées par SUMO® qui figurent sur
            son Compte Utilisateur, d’interpréter à distance ces données et, le
            cas échéant, de prendre des décisions relatives à la prise en charge
            médicale du patient.
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            Dans le cadre de ce dispositif d’assistance diagnostic et pronostic,
            l’Utilisateur bénéficie d’un outil de messagerie spécifique qui lui
            permet d’échanger directement avec le patient.
          </p>

          <nav id="to-top" className="text-blue-500 text-base lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
          <p
            id="restrictions"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-2xl my-4 font-bold lg:text-2xl"
          >
            Restrictions
          </p>
          <p className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-xl my-4 font-bold lg:text-base">
            L’Utilisateur accepte et reconnaît que :
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            Les résultats obtenus à partir des informations saisies dans SUMO®
            Pro doivent être interprétées par lui-même, un professionel de santé
            expérimenté et spécialisé en chirurgie du rachis, en prenant en
            compte l’historique médical complet du patient ;
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            Conformément aux exigences de la réglementation européenne
            (Directive Européenne 93/42/CEE) relative aux dispositifs médicaux,
            le Produit est assimilé à un dispositif médical ;
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            Enfin, l’Utilisateur reconnaît qu’il assume l’entière responsabilité
            de toute décision ou action prise ou non en fonction des
            informations fournies par SUMO® Pro. L’Utilisateur reste seul
            responsable de toute action réalisée manuellement au travers de
            l’application. L’Utilisateur reconnaît en outre qu’il est seul
            responsable du diagnostic et du traitement proposé. L’Utilisateur
            reconnaît qu’il devra indemniser, dégager de toute responsabilité et
            défendre CORTEXX MEDICAL INTELLIGENCE contre toute réclamation
            relative au diagnostic et/ou au(x) traitement(s) proposé(s) à son
            patient, quand bien même cette réclamation impliquerait
            l’utilisation de SUMO® Pro.
          </p>
          <p className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-xl my-4 font-bold lg:text-base">
            L’Utilisateur s’engage à ne pas - et à faire respecter ces
            interdictions par tout tiers - :
          </p>
          <ol type="i">
            <li>
              <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
                • utiliser ou reproduire tout ou partie de SUMO® sous forme de
                code source ;
              </p>
            </li>
            <li>
              <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
                • traduire, effectuer des opérations de reverse-engineering,
                décompiler ou désassembler SUMO®;
              </p>
            </li>
            <li>
              <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
                • supprimer ou modifier tout droit d’auteur, marquage, droit de
                propriété intellectuelle intégré dans SUMO® tel que fourni à
                l’Utilisateur, ou
              </p>
            </li>
            <li>
              <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
                • violer toute obligation de confidentialité, telle que définie
                ci-après.
              </p>
            </li>
          </ol>
          <p className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-xl my-4 font-bold lg:text-base">
            L’Utilisateur reconnaît que toute utilisation de SUMO® Pro en
            relation avec tout produit, système, application ou matériel autre
            que ceux expressément autorisés par CORTEXX MEDICAL INTELLIGENCE,
            est interdite.
          </p>
          <p className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-xl my-4 font-bold lg:text-base">
            L’Utilisateur s’engage à ne pas télécharger, publier, envoyer par
            e-mail, transmettre via SUMO® Pro des informations ou contenus qui
            pourraient porter atteinte aux droits de propriété intellectuelle
            d’un tiers, sauf autorisation expresse écrite et sous réserve du
            strict respect des règles de droit applicables notamment en matière
            de protection des données personnelles et données de santé.
          </p>

          <nav id="to-top" className="text-blue-500 text-base lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
          <p
            id="intellectual-property"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-2xl my-4 font-bold lg:text-2xl"
          >
            Propriété intellectuelle
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            Il est expressément rappelé que CORTEXX MEDICAL INTELLIGENCE®
            détient l’intégralité des droits de propriété intellectuelle sur
            l’application SUMO® Pro, ainsi que sur tous les manuels, dessins,
            informations techniques et documentations associés (la "
            Documentation “), y compris, sans limitation, tous les brevets,
            droits d’auteur, marques déposées, secrets commerciaux, autres
            droits de propriété intellectuelle, savoir-faire ou autres droits
            exclusifs (” Droits de propriété intellectuelle ") dans et sur tous
            leurs composants et éléments, qui restent la propriété unique et
            exclusive de CORTEXX MEDICAL INTELLIGENCE. CORTEXX MEDICAL
            INTELLIGENCE®, et SUMO® Pro sont des marques dûment enregistrées
            appartenant à CORTEXX MEDICAL INTELLIGENCE®.
          </p>

          <nav id="to-top" className="text-blue-500 text-base lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
          <p
            id="confidentiality"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-2xl my-4 font-bold lg:text-2xl"
          >
            Confidentialité
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            Les Informations Confidentielles de CORTEXX MEDICAL INTELLIGENCE
            comprennent notamment, sans que cette liste ne soit limitative,
            l’ensemble des données de quelque nature qu’elle soit, relatives à
            SUMO® Pro, ainsi que toutes les spécifications de design, les
            programmes de conception et plus généralement tout son savoir-faire.
            L’Utilisateur s’engage à conserver les Informations Confidentielles
            de CORTEXX MEDICAL INTELLIGENCE® et de SUMO® Pro secrètes et
            confidentielles et à ne pas les divulguer, les distribuer, les
            publier, les reproduire, les transmettre à des tiers partiellement
            ou totalement, directement ou indirectement et de quelque manière
            que ce soit. L’Utilisateur maintiendra la confidentialité desdites
            Informations Confidentielles avec le même degré de protection que
            pour ses propres informations Confidentielles. La présente
            obligation de confidentialité reste en vigueur pendant toute la
            durée d’utilisation de SUMO® Pro et pendant une période de cinq (5)
            ans suivant la fin de l’utilisation pour quelque raison qu’elle
            soit. Les Informations Confidentielles seront archivées dans les
            conditions définies dans la Politique de confidentialité accessible
            sur ce lien :
          </p>
          <a
            href="https://cortexxmi.com/sumo/pro-privacy"
            className="lg:w-3/4 w-full leading-snug text-blue-500 text-base lg:text-base font-bold lg:text-sm underline"
          >
            https://cortexxmi.com/sumo/pro-privacy
          </a>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            Conformément au Règlement général sur la protection des données (dit
            « RGPD ») et à la loi Informatique et Libertés, l’Utilisateur est
            informé qu’il dispose de ses droits sur ses données également
            pendant toute la durée de l’archivage de celles-ci. Pour en savoir
            plus sur ses droits, il est invité à consulter la Politique de
            confidentialité accessible sur ce lien :
          </p>
          <a
            href="https://cortexxmi.com/sumo/pro-privacy"
            className="lg:w-3/4 w-full leading-snug text-blue-500 text-base lg:text-base font-bold lg:text-sm underline"
          >
            https://cortexxmi.com/sumo/pro-privacy
          </a>

          <nav id="to-top" className="text-blue-500 text-base lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
          <p
            id="tcus-time"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-2xl my-4 font-bold lg:text-2xl"
          >
            Durée des conditions générales d’utilisation
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            Les présentes prennent effet pour l’Utilisateur au jour de la
            création de son compte Utilisateur et de l’acceptation des
            Conditions Générales d’Utilisation. Elles restent en vigueur pendant
            toute la durée de l’inscription de l’Utilisateur aux services
            fournis par SUMO® Pro. La résiliation de l’inscription prend effet
            soit le jour de la demande de désinscription de l’Utilisateur, soit
            le jour de la résiliation de son inscription par CORTEXX MEDICAL
            INTELLIGENCE, conformément aux conditions décrites dans les
            présentes. L’autorisation accordée à l’Utilisateur prendra
            automatiquement fin sans préavis de la part de CORTEXX MEDICAL
            INTELLIGENCE en cas de non-respect par l’Utilisateur de l’une
            quelconque des dispositions des CGU. La résiliation interviendra dès
            la réception par l’Utilisateur du courrier électronique adressé en
            ce sens par CORTEXX MEDICAL INTELLIGENCE. De la même manière,
            l’Utilisateur peut résilier les CGU en notifiant cette décision à
            CORTEXX MEDICAL INTELLIGENCE par e-mail à tout moment. En cas de
            résiliation, CORTEXX MEDICAL INTELLIGENCE fermera le compte de
            l’Utilisateur et SUMO® Pro ne sera plus accessible à l’Utilisateur.
            Les limitations de garantie et de responsabilité précisées ci-après
            continueront à produire leurs effets après toute résiliation et
            pendant la durée de prescription s’appliquant aux droits et
            obligations en cause.
          </p>
          <nav id="to-top" className="text-blue-500 text-base lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>

          <p
            id="lialability"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-2xl my-4 font-bold lg:text-2xl"
          >
            Limitations de garanties et responsabilités
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-xl font-bold lg:text-base my-4">
            Le produit fonctionne conformément à la documentation et aux
            exigences de la loi applicable.
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            En sa qualité de professionnel, et plus particulièrement de
            professionnel de santé, l’Utilisateur assume l’ensemble des
            responsabilités et des risques liés à ces activités, y compris les
            activités médicales. l’Utilisateur assume l’entière responsabilité
            et les risques des activités qu’il doit effectuer en utilisant le
            produit et/ou pour l’utilisation de tout système, contenu,
            équipement ou solution tiers, y compris tout site web. CORTEXX
            MEDICAL INTELLIGENCE ne peut en outre être tenu responsable de tout
            dommage, défaut, dysfonctionnement ou non-conformité résultant d’une
            violation par l’Utilisateur de toute règle de droit applicable ou de
            normes ou directives professionnelles ou de tout ou partie des
            présentes conditions, de la documentation ou des instructions de
            CORTEXX MEDICAL INTELLIGENCE concernant l’utilisation du produit. La
            limitation de responsabilité susmentionnée ne s’applique que dans la
            mesure permise par la loi applicable et ne peut s’appliquer dans un
            certain nombre de cas de responsabilité qui ne peuvent être limités
            ou exclus conformément aux règles d’ordre public de la loi
            applicable. La responsabilité pour produits défectueux selon l’art.
            1245 et suivants du code civil français ne peut pas non plus être
            exclue, sauf dans les cas expressément prévus par la loi.
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-xl font-bold lg:text-base my-4">
            CORTEXX MEDICAL INTELLIGENCE se réserve la possibilité de limiter
            temporairement certaines fonctionnalités de SUMO® Pro si cela
            s’avère nécessaire pour des raisons de sécurité, de respect de
            l’intégrité des données, ou encore pour prendre des mesures
            techniques, dans le but de fournir des services performants
            (notamment dans le cadre de travaux de maintenance).
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-xl font-bold lg:text-base my-4">
            CORTEXX MEDICAL INTELLIGENCE n’est tenu par aucune obligation de
            garantie en cas de défaillance de SUMO® Pro qui résulterait d’un
            accident, d’une utilisation abusive, d’une application indue ou de
            toute autre cause échappant au contrôle de CORTEXX MEDICAL
            INTELLIGENCE. De la même manière, la responsabilité de CORTEXX
            MEDICAL INTELLIGENCE ne peut être engagée en cas notamment de non
            implémentation par l’Utilisateur de toutes les fonctionnalités,
            révisions, modifications, mises à jour, correctifs ou nouvelles
            versions fournies par CORTEXX MEDICAL INTELLIGENCE.
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-xl font-bold lg:text-base my-4">
            CORTEXX MEDICAL INTELLIGENCE n’est pas responsable d’une perte de
            données ou d’une violation de confidentialité. Il incombe ainsi à
            l’Utilisateur de s’assurer que les données des patients sont
            convenablement protégées contre les violations de sécurité et de
            confidentialité conformément aux lois et réglementations locales
            et/ou internationales en vigueur.
          </p>

          <nav id="to-top" className="text-blue-500 text-base lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
          <p
            id="data-privacy"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-2xl my-4 font-bold lg:text-2xl"
          >
            Protection des données
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-xl font-bold lg:text-base my-4">
            Responsabilité de CORTEXX MEDICAL INTELLIGENCE sur les données
            personnelles de l’Utilisateur
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            CORTEXX MEDICAL INTELLIGENCE peut, en sa qualité de responsable du
            traitement des données, collecter et traiter ultérieurement les
            données personnelles relatives à l’Utilisateur (c’est-à-dire les
            données d’identification et de contact). Le traitement de ces
            données collectées est nécessaire à l’utilisation de SUMO® Pro.
            CORTEXX MEDICAL INTELLIGENCE s’engage à traiter ces données
            conformément aux règles prévues par le règlement 2016/679 du 27
            avril 2016 relatif à la protection des personnes physiques à l’égard
            du traitement des données à caractère personnel (le “RGPD”) et, à
            titre subsidiaire, par la loi n° 78-17 du 6 janvier 2018 modifiée
            (la “Loi Informatique et Libertés”). Aux fins de ce qui précède, les
            termes suivants ont la même signification que celle contenue dans le
            RGPD : “Données à caractère personnel”, “personne concernée”,
            “responsable du traitement”, “données à caractère personnel”,
            “données relatives à la santé”, “traitement” et “autorité de
            contrôle”. Les données de l’Utilisateur sont traitées exclusivement
            pour l’exécution des présentes Conditions, la gestion de la relation
            client, les sollicitations commerciales, les études et enquêtes,
            l’exploitation et la maintenance de SUMO® Pro ainsi que les demandes
            de l’Utilisateur pour exercer ses droits individuels. L’Utilisateur
            doit fournir des informations exactes, à jour et complètes lors de
            l’enregistrement de son compte. Sans préjudice de toute autre
            obligation en vertu des présentes, la fourniture d’une fausse
            identité ou de fausses informations sur le statut professionnel lors
            de l’accès à SUMO® Pro est expressément interdite. CORTEXX MEDICAL
            INTELLIGENCE se réserve le droit de demander à tout moment la preuve
            de l’identité et/ou du statut de l’Utilisateur en tant que
            professionnel de santé et de suspendre ou de résilier sans préavis
            les présentes Conditions, si l’Utilisateur n’en fournit pas la
            preuve correspondante. Seuls les salariés de CORTEXX MEDICAL
            INTELLIGENCE responsables de l’exécution des présentes Conditions,
            de l’exploitation et de la maintenance de SUMO® Pro ou chargés de
            tâches administratives au sein de CORTEXX MEDICAL INTELLIGENCE
            peuvent accéder aux données de l’Utilisateur. Elles pourront
            également être communiquées aux prestataires de services agréés de
            CORTEXX MEDICAL INTELLIGENCE impliqués dans l’exécution des
            présentes Conditions ou dans la mise en œuvre des finalités
            précitées. Les données d’un Utilisateur européen ne sont pas
            transférées en dehors de l’Union européenne. Les données de
            l’Utilisateur seront conservées pendant la durée des Conditions, à
            l’exception des données nécessaires au respect des obligations
            légales ou à la preuve de l’existence ou de l’étendue ou des
            conditions d’exercice d’un droit ou d’un contrat, qui pourront être
            archivées par CORTEXX MEDICAL INTELLIGENCE conformément à la loi
            applicable. Sous réserve des conditions prévues par la Loi
            Informatique et Libertés, l’Utilisateur dispose notamment d’un droit
            d’accès, de rectification ou d’effacement de ses données
            personnelles, d’un droit de limitation de leur traitement et
            d’opposition à leur traitement ainsi que d’un droit de regard sur
            l’utilisation qui sera faite de ses données personnelles après son
            décès. Ces droits peuvent être exercés par courrier électronique à
            l’adresse suivante : dpo@cortexxmi.com L’Utilisateur a également la
            possibilité de porter plainte auprès de l’autorité de contrôle
            compétente en matière de protection des données (CNIL en France).
            Les données seront archivées dans les conditions définies dans la
            Politique de confidentialité accessible sur ce lien :
          </p>
          <a
            href="https://cortexxmi.com/sumo/pro-privacy"
            className="lg:w-3/4 w-full leading-snug text-blue-500 text-base lg:text-base font-bold underline"
          >
            https://cortexxmi.com/sumo/pro-privacy
          </a>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            Conformément au Règlement général sur la protection des données (dit
            « RGPD ») et à la loi Informatique et Libertés, l’Utilisateur est
            informé qu’il dispose de ses droits sur ses données également
            pendant toute la durée de l’archivage de celles-ci. Pour en savoir
            plus sur ses droits, il est invité à consulter la Politique de
            confidentialité accessible sur ce lien :
          </p>
          <a
            href="https://cortexxmi.com/sumo/pro-privacy"
            className="lg:w-3/4 w-full leading-snug text-blue-500 text-base lg:text-base font-bold underline"
          >
            https://cortexxmi.com/sumo/pro-privacy
          </a>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-xl font-bold lg:text-base my-4">
            Responsabilité concernant les données personnelles collectées ou
            traitées par ou pour le compte de l’Utilisateur par l’utilisation de
            SUMO® Pro.
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            Le Compte Utilisateur est considéré comme personnel. Les données
            personnelles des patients de l’Utilisateur seront accessibles
            uniquement via le Compte de l’Utilisateur sous le contrôle et la
            responsabilité exclusive de l’Utilisateur. Il est de la seule
            responsabilité de l’Utilisateur de se conformer à toutes les
            obligations applicables en sa qualité de responsable du traitement
            des données. Sans limitation, l’Utilisateur mettra en œuvre toutes
            les mesures techniques et organisationnelles appropriées et assurera
            la sécurité des données personnelles (y compris leur
            confidentialité, leur disponibilité et leur intégrité), la
            minimisation de leur traitement, la durée limitée requise ou les
            conditions certifiées de leur conservation, leurs transferts
            valables en dehors de l’Union européenne selon le cas. Il appartient
            également à l’Utilisateur de respecter les droits et libertés des
            personnes concernées dont les données à caractère personnel sont
            traitées (y compris l’information, la transparence, le consentement,
            l’accès, la limitation, les objections, la portabilité,
            l’effacement, le contrôle post mortem des données et les recours
            dans la mesure où ces droits sont applicables). CORTEXX MEDICAL
            INTELLIGENCE, en tant que responsable du traitement, s’emploie et
            s’engage à protéger la vie privée et les données à caractère
            personnel des Utilisateurs en conformité avec les exigences légales
            et notamment le Règlement n°2016/679 du 27 avril 2016 relatif à la
            protection des personnes physiques à l’égard du traitement des
            données à caractère personnel et à la libre circulation de ces
            données et la Loi n°78-17 du 6 janvier 1978 relative à
            l’informatique, aux fichiers et aux libertés. Les informations
            relatives à la manière dont CORTEXX MEDICAL INTELLIGENCE protège les
            données à caractère personnel des Utilisateurs, qu’elle collecte
            dans le cadre du téléchargement et de l’utilisation de SUMO®, et aux
            engagements pris par CORTEXX MEDICAL INTELLIGENCE afin de veiller au
            respect des données à caractère personnel des Utilisateurs font
            l’objet d’une Politique de protection de données distincte,
            disponible à l’adresse suivante :
          </p>
          <a
            href="https://cortexxmi.com/sumo/pro-privacy"
            className="lg:w-3/4 w-full leading-snug text-blue-500 text-base lg:text-base font-bold underline"
          >
            https://cortexxmi.com/sumo/pro-privacy
          </a>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-xl font-bold lg:text-base my-4">
            Sécurité
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            L’Utilisateur ne doit divulguer son identifiant et son mot de passe
            à personne. Il doit prendre toutes les mesures appropriées pour
            assurer la sécurité et la confidentialité de ses renseignements
            personnels. Si l’Utilisateur sait ou soupçonne qu’une personne autre
            que lui-même connaît son mot de passe ou toute autre information le
            concernant, l’Utilisateur doit en informer CORTEXX MEDICAL
            INTELLIGENCE dans les plus brefs délais, comme rappelé ci-après
            relativement aux Incidents de Sécurité. L’Utilisateur est
            entièrement responsable de toutes les activités survenant à travers
            l’utilisation de son mot de passe. Les parties s’aviseront
            mutuellement dans les plus brefs délais de tout incident qu’une
            partie croit raisonnablement être un incident de sécurité ("
            incident de sécurité “) dont une partie prend connaissance. Si une
            partie (ou l’une de ses sociétés affiliées, sous-traitants, employés
            ou représentants) cause ou est à l’origine de l’incident de
            sécurité, ou est obligée de le faire en vertu des lois sur la
            protection des données, aux frais de la partie d’origine, celle-ci
            doit prendre rapidement toutes les mesures correctives nécessaires
            et appropriées si et selon les lois sur la protection des données,
            incluant, sans limitation, la notification à l’utilisateur en sa
            qualité de contrôleur des données, pour les personnes dont les
            données personnelles peuvent avoir été affectées par l’utilisation
            de SUMO® Pro. Les parties conviennent qu’en cas d’incident de
            sécurité, elles collaboreront de bonne foi pour y répondre dans les
            délais conformes aux lois sur la protection des données.
            <br />
            Il est expressément convenu que ”Incident de sécurité" signifie,
            uniquement pour ce qui concerne les données personnelles traitées
            par ou via SUMO® Pro, l’accès, l’utilisation, la divulgation, la
            modification ou la destruction non autorisé(s) de ces données
            personnelles dont une partie a connaissance ou devrait,
            raisonnablement avoir connaissance, sauf dans toute situation qui ne
            s’assimilerait pas à un incident de sécurité conformément à la loi
            sur la protection des données.
          </p>

          <p className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-xl my-4 font-bold lg:text-base">
            Hébergement
          </p>

          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            Les données collectées via SUMO® sont hébergées dans
            l’infrastructure cloud publique de Google Cloud Platform. Google
            Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland.
          </p>
          <a
            href="https://cloud.google.com"
            className="lg:w-3/4 w-full leading-snug text-blue-500 text-base lg:text-base font-bold lg:text-sm underline"
          >
            https://cloud.google.com
          </a>

          <nav id="to-top" className="text-blue-500 text-base lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>

          <p
            id="respect-tcus"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-2xl my-4 font-bold lg:text-2xl"
          >
            Non-respect des CGUs
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            En cas de manquement de l’Utilisateur à ses obligations au titre des
            présentes CGU, c’est-à-dire découlant des articles 1 à 13 des
            présentes CGU, ou au titre de toute loi ou réglementation
            applicable, CORTEXX MEDICAL INTELLIGENCE se réserve le droit de
            prendre toute mesure qu’elle jugerait utile et en particulier de
            supprimer le Contenu Utilisateur et les Données de Suivi publiés par
            l’Utilisateur sur SUMO® Pro, de suspendre et/ou supprimer le Compte
            Utilisateur de l’Utilisateur et/ou de mettre fin au droit d’accès,
            d’affichage et d’utilisation concédé à l’Utilisateur{" "}
            <a
              href="#modify-user-account"
              className="text-blue-500 text-base lg:text-base"
            >
              ci-dessus
            </a>{" "}
            , après notification du manquement ou, en cas de manquement pouvant
            être corrigé, après mise en demeure préalable de se mettre en
            conformité non suivie d’effet dans un délai raisonnable indiqué dans
            ladite mise en demeure. L’Utilisateur reconnaît et accepte d’ores et
            déjà qu’en cas de manquement susceptible de porter atteinte à la
            sécurité de SUMO® Pro et/ou en cas de publication de tout contenu
            non conforme aux présentes CGU, CORTEXX MEDICAL INTELLIGENCE pourra
            prendre les mesures susvisées immédiatement après notification du
            manquement à l’Utilisateur.
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            Ces mesures sont sans préjudice de tout autre droit de CORTEXX
            MEDICAL INTELLIGENCE, notamment les dommages et intérêts auxquels
            CORTEXX MEDICAL INTELLIGENCE pourrait prétendre.
          </p>

          <nav id="to-top" className="text-blue-500 text-base lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
          <p
            id="major-force"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-2xl my-4 font-bold lg:text-2xl"
          >
            Force majeure
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            En cas de survenance d’un événement de force majeure, tel que défini
            à l’article 1218 du Code civil, entraînant un empêchement temporaire
            d’exécution pour l’une des parties, les obligations respectives des
            parties seront suspendues et aucune partie n’encourra de
            responsabilité de ce fait. Si l’événement de force majeure perdure
            pendant plus de trente (30) jours consécutifs, et/ou que, dès son
            intervention il entraîne un empêchement définitif d’exécution pour
            l’une des parties, la partie à laquelle le cas de force majeure est
            opposé sera habilitée à résilier de plein droit les présentes CGU.
          </p>

          <nav id="to-top" className="text-blue-500 text-base lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
          <p
            id="general-dispositions"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 text-2xl my-4 font-bold lg:text-2xl"
          >
            Dispositions Générales
          </p>
          <p className="lg:w-3/4 w-full leading-normal text-gray-500 text-base lg:text-base my-4">
            Les présentes Conditions constituent l’intégralité de l’accord entre
            CORTEXX MEDICAL INTELLIGENCE et l’Utilisateur et se substituent à
            tout autre accord ou discussion, oral ou écrit, en relation avec
            l’objet des présentes ; elles ne peuvent être modifiées que par un
            avenant écrit aux présentes ou une version révisée dûment acceptée
            par les parties. Si le Produit est utilisé à partir d’un appareil
            appartenant à une société ou autre entité juridique, les CGU sont
            formées par et entre CORTEXX MEDICAL INTELLIGENCE et cette entité.
            La personne qui accepte les termes des CGU déclare et garantit à
            CORTEXX MEDICAL INTELLIGENCE qu’elle a l’autorité pour engager
            ladite entité dans les termes des CGU. L’Utilisateur s’engage à
            défendre, indemniser et dégager de toute responsabilité CORTEXX
            MEDICAL INTELLIGENCE, ses employés, dirigeants, affiliés, agents,
            distributeurs, revendeurs et sous-traitants contre toute
            réclamation, perte, responsabilité, dommage, coûts et dépenses, y
            compris les frais d’avocat et frais judiciaires raisonnables, que de
            tels dommages pourraient causer en cas de non-respect des conditions
            d’utilisation du Produit par l’Utilisateur ou d’une utilisation non
            autorisée de celui-ci. L’Utilisateur ne pourra en aucun cas céder,
            transférer ou sous-licencier des obligations ou des avantages
            issu(s) des présentes Conditions sans le consentement écrit
            préalable exprès de CORTEXX MEDICAL INTELLIGENCE. Les Conditions
            devront être interprétées et appliquées conformément au droit
            français. En cas de litige, les tribunaux de Paris sont seuls
            compétents, sauf disposition contraire de la loi. Si une disposition
            des présentes Conditions est déclarée invalide, illégale ou
            inapplicable par un tribunal compétent, une telle disposition sera
            considérée comme invalide et toutes les autres dispositions
            demeureront pleinement en vigueur.
          </p>

          <nav id="to-top" className="text-blue-500 text-base lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
        </article>
      </body>
    </div>
  )
}

export default TermsPro
