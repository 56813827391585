import React from "react";
import GuideBase from "../../components/GuideBase";
import useLanguage from "../../components/hooks";

const listOfLinks = {
  fr: {
    1: "https://www.ameli.fr/paris/assure/sante/themes/tabac",
    2: "https://pubmed.ncbi.nlm.nih.gov/23095839/",
  },
  en: {
    1: "https://www.ameli.fr/paris/assure/sante/themes/tabac",
    2: "https://pubmed.ncbi.nlm.nih.gov/23095839/",
  },
};

const Smoking = () => {
  const [t, links] = useLanguage(listOfLinks);

  return (
    <GuideBase>
      <header>
        <p class="lg:w1/2 my-4 w-full text-3xl font-bold leading-relaxed text-gray-600">
          {t("smoking.title")}
        </p>
      </header>
      <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
        {t("smoking.intro")}
      </p>
      <nav>
        <p
          id="contents"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("common.summary")}
        </p>

        <ul>
          <li>
            <a
              href="#pain"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("smoking.summary.pain")}
            </a>
          </li>
          <li>
            <a
              href="#stop"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("smoking.summary.stop")}
            </a>
          </li>
          <li>
            <a
              href="#sources"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("common.sources")}
            </a>
          </li>
        </ul>
      </nav>
      <article>
        <h2
          id="pain"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("smoking.summary.pain")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("smoking.pain.content.1")}
        </p>

        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("smoking.pain.content.2")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("smoking.pain.content.3")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("smoking.pain.content.4")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("smoking.pain.content.5")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="stop"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("smoking.summary.stop")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("smoking.stop.content")}
          <a
            href={links[1]}
            class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
          >
            {[" ", t("smoking.links.1"), "."].join("")}
          </a>
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>

        <h2
          id="sources"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("common.sources")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("smoking.extra_infos.1")}
          <a
            href={links[2]}
            class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
          >
            {[" ", t("smoking.links.2"), "."].join("")}
          </a>
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
      </article>
    </GuideBase>
  );
};

export default Smoking;
