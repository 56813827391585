import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const useLanguage = (listOfLinks) => {
  const { t, i18n } = useTranslation(["guides"]);
  const { lang } = useParams();

  const [links, setLinks] = useState(listOfLinks.fr);

  useEffect(() => {
    i18n.changeLanguage(lang || "fr");
    let _links = [];
    if (lang !== "fr") {
      _links = Object.fromEntries(
        Object.entries(listOfLinks.en).map(([k, _link], i) => {
          if (_link.includes("sumo/guides")) {
            return [k, _link.replace("/en", `/${lang}`)];
          } else return [k, _link];
        })
      );
    } else _links = listOfLinks[lang || "en"];
    setLinks(_links);
  }, [lang, i18n, listOfLinks]);

  return [t, links];
};

export default useLanguage;
