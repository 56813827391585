import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import loading from "./loading.svg";
import Alimentation from "./views/guides/Alimentation";
import PainManagementFirstPart from "./views/guides/PainManagement_1";
import PainManagementSecondPart from "./views/guides/PainManagement_2";
import PhysicalActivity from "./views/guides/PhysicalActivity";
import ProfessionalActivity from "./views/guides/ProfessionalActivity";
import PsychologicalAdvices from "./views/guides/PsychologicalAdvices";
import Sleep from "./views/guides/Sleep";
import Smoking from "./views/guides/Smoking";
import WellBeing from "./views/guides/WellBeing";
import Homepage from "./views/Homepage";
import Privacy from "./views/Privacy";
import PrivacyPro from "./views/PrivacyPro";
import TermsPro from "./views/TermPro";
import Terms from "./views/Terms";
import UserGuide from "./views/UserGuide";

// loading component for suspense fallback
const Loader = () => (
  <div className="grid h-screen place-items-center">
    <img src={loading} className="App-loading" alt="loading" />
    <div>Loading...</div>
  </div>
);

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Routes>
          <Route exact path="/sumo/user-terms/:lang?" element={<Terms />} />
          <Route exact path="/sumo/user-privacy/:lang?" element={<Privacy />} />
          <Route exact path="/sumo/user-guide/:lang?" element={<UserGuide />} />
          <Route exact path="/sumo/pro-terms" element={<TermsPro />} />
          <Route exact path="/sumo/pro-privacy" element={<PrivacyPro />} />
          <Route
            path="/sumo/guides/feeding/:lang?"
            element={<Alimentation />}
          />
          <Route
            path="/sumo/guides/pain-management-first-part/:lang?"
            element={<PainManagementFirstPart />}
          />
          <Route
            path="/sumo/guides/pain-management-second-part/:lang?"
            element={<PainManagementSecondPart />}
          />
          <Route
            path="/sumo/guides/physical-activity/:lang?"
            element={<PhysicalActivity />}
          />
          <Route
            path="/sumo/guides/professional-activity/:lang?"
            element={<ProfessionalActivity />}
          />
          <Route
            path="/sumo/guides/psychological-advices/:lang?"
            element={<PsychologicalAdvices />}
          />
          <Route path="/sumo/guides/sleep/:lang?" element={<Sleep />} />
          <Route
            path="/sumo/guides/smoking-management/:lang?"
            element={<Smoking />}
          />
          <Route path="/sumo/guides/wellbeing/:lang?" element={<WellBeing />} />
          <Route exact path="/" element={<Homepage />} />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default App;
