import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import ReactPlayer from "react-player";

const News = () => {
  const { t, i18n } = useTranslation();

  const [publications, setPublications] = useState([]);

  useEffect(() => {
    const lng = i18n.language;
    axios
      .get(`./locales/${lng}/publications.json`)
      .then((res) => setPublications(sortPublicationsbyDate(res.data)));
  }, [i18n.language]);

  const sortPublicationsbyDate = (_pubs) => {
    return _pubs.sort((a, b) => new Date(b.date) - new Date(a.date));
  };

  const getMedias = (medias) => medias.map((_media) => getMedia(_media));

  const getMedia = (media) => {
    switch (media.type) {
      case "LINK":
        return (
          <div className="pt-4">
            <a
              className="sm:text-m text-sm font-semibold text-cortexx-600"
              href={media.value}
            >
              {media.display}
            </a>
          </div>
        );
      case "IMAGE":
        return (
          <div className="h-auto w-auto p-4">
            <img src={media.value} className="mx-auto" alt={media.display} />
          </div>
        );
      case "VIDEO":
        return (
          <ReactPlayer
            url={media.value}
            controls={true}
            width={285}
            height={170}
          />
        );
      default:
        return;
    }
  };

  return (
    <section id="news" className="body-font bg-cortexx-900">
      <div className="container mx-auto px-5 pt-10 md:pt-20">
        <div className="flex w-full flex-wrap">
          <div className="mb-10">
            <h1 class="text-4xl font-extrabold tracking-tight text-white md:text-4xl">
              {t("news.title")}
            </h1>
          </div>
        </div>
        <div className="flex overflow-x-auto pb-10">
          <div className="flex flex-nowrap">
            {publications.map((_pub) => {
              return (
                <div className="inline-block px-3">
                  <div className=" h-full w-80 max-w-xs overflow-hidden rounded-lg bg-white px-4 py-4 shadow-md transition-shadow duration-300 ease-in-out hover:shadow-xl">
                    <h2 className="sm:text-l text-l mb-2 font-bold text-cortexx-900">
                      {_pub.title}
                    </h2>
                    <text className="sm:text-m text-m title-font mb-2 font-normal text-cortexx-700">
                      {i18n.language === "fr" ? "Le " : "On "}
                      {Intl.DateTimeFormat()
                        .format(new Date(_pub.date))
                        .toString()}
                    </text>
                    <div
                      className="sm:text-m mx-auto mb-2 items-center pt-6 text-sm text-gray-800 "
                      dangerouslySetInnerHTML={{ __html: _pub.description }}
                    />
                    {_pub.medias && getMedias(_pub.medias)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default News;
