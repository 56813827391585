import React from "react";
import GuideBase from "../../components/GuideBase";
import useLanguage from "../../components/hooks";

const listOfLinks = {
  fr: {
    1: "https://sante.gouv.fr/IMG/pdf/la_douleur_en_questions.pdf",
    2: "https://sante.gouv.fr/",
  },
  en: {
    1: "https://sante.gouv.fr/IMG/pdf/la_douleur_en_questions.pdf",
    2: "https://sante.gouv.fr/",
  },
};

const PainManagementFirstPart = () => {
  const [t, links] = useLanguage(listOfLinks);

  return (
    <GuideBase>
      <header>
        <p class="lg:w1/2 my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:text-3xl">
          {t("pain_management_1.title")}
        </p>
      </header>
      <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
        {t("pain_management_1.intro")}
      </p>
      <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
        <img
          src="/img/guides/preanesthesy_learning.jpg"
          class="object-cover object-center"
          alt="preanesthesy_learning"
        />
      </p>
      <nav>
        <p
          id="contents"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("common.summary")}
        </p>

        <ul>
          <li>
            <a
              href="#fear"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("pain_management_1.summary.fear")}
            </a>
          </li>
          <li>
            <a
              href="#drugs"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("pain_management_1.summary.drugs")}
            </a>
          </li>
          <li>
            <a
              href="#other_methods"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("pain_management_1.summary.other_methods")}
            </a>
          </li>
          <li>
            <a
              href="#stop_treatment"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("pain_management_1.summary.stop_treatment")}
            </a>
          </li>
          <li>
            <a
              href="#exit"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("pain_management_1.summary.exit")}
            </a>
          </li>
        </ul>
      </nav>

      <article>
        <h2
          id="fear"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("pain_management_1.summary.fear")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_1.fear.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_1.extra_infos.1")}
          <a
            href={links[1]}
            class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
          >
            {[" ", t("pain_management_1.links.1")].join("")}
          </a>
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="drugs"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("pain_management_1.summary.drugs")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-1/2 lg:text-base">
          <img
            src="/img/guides/preanesthesy_drugs.png"
            class="object-cover object-center"
            alt="preanesthesy_drugs"
          />
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_1.drugs.content.1")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="other_methods"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("pain_management_1.summary.other_methods")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_1.other_methods.content.1")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="stop_treatment"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("pain_management_1.summary.stop_treatment")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_1.stop_treatment.content.1")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="exit"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("pain_management_1.summary.exit")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_1.exit.content.1")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
      </article>
    </GuideBase>
  );
};

export default PainManagementFirstPart;
