import {
  ChartBarIcon,
  DevicePhoneMobileIcon,
  ShieldCheckIcon,
  SparklesIcon,
} from "@heroicons/react/24/solid";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useScreenSize from "../../utils/useScreenSize";
import DeepSurgeryAngledCard from "../../components/AngledCard";
import SolutionCard from "../../components/SolutionCard";
import FunctionsCard from "../../components/FunctionsCard";

const cards = [
  {
    name: "data-collection",
    title: "data_collection.title_short",
    corpusText: "data_collection.description",
    color: "to-cortexx-300",
    icon: (
      <DevicePhoneMobileIcon className="h-4 w-4 text-white sm:h-6 sm:w-6" />
    ),
    link: "#data_collection",
  },
  {
    name: "storage",
    title: "storage.title_short",
    corpusText: "storage.description",
    color: "to-cortexx-400",
    icon: <ShieldCheckIcon className="h-4 w-4 text-white sm:h-6 sm:w-6" />,
    link: "#storage",
  },
  {
    name: "analysis",
    title: "analysis.title",
    corpusText: "analysis.description",
    color: "to-cortexx-800",
    icon: <ChartBarIcon className="h-4 w-4 text-white sm:h-6 sm:w-6" />,
    link: "#analysis",
  },
  {
    name: "prediction",
    title: "prediction.title",
    corpusText: "prediction.description",
    color: "to-cortexx-200",
    icon: <SparklesIcon className="h-4 w-4 text-white sm:h-6 sm:w-6" />,
    link: "#prediction",
  },
];

const preOpCards = [
  {
    name: "pre_op_cards.1",
    opacity: "opacity-40",
  },
  {
    name: "pre_op_cards.2",
    opacity: "opacity-70",
  },
  {
    name: "pre_op_cards.3",
    opacity: "opacity-100",
  },
  {
    name: "pre_op_cards.4",
    opacity: "opacity-70",
  },
  {
    name: "pre_op_cards.5",
    opacity: "opacity-50",
  },
  {
    name: "pre_op_cards.6",
    opacity: "opacity-30",
  },
];

const postopCards = [
  {
    id: "patient",
    name: "patient.title",
    description: "patient.text",
    icon: (
      <img className="m-auto max-h-16" src="./img/icons/patient.png" alt="" />
    ),
    sectionLink: "#patient_benefits",
  },
  {
    id: "surgeon",
    name: "surgeon.title",
    description: "surgeon.text",
    icon: (
      <img className="m-auto max-h-16" src="./img/icons/surgeon.png" alt="" />
    ),
    sectionLink: "#usecases",
  },
  {
    id: "research",
    name: "research.title",
    description: "research.text",
    icon: (
      <img className="m-auto max-h-16" src="./img/icons/research.png" alt="" />
    ),
    sectionLink: "#usecases",
  },
];

const Platform = () => {
  const { t, i18n } = useTranslation("translation", { keyPrefix: "platform" });
  const { lang } = useParams();
  const screenSize = useScreenSize();
  const intervalIDRef = useRef(null);

  const [paddingLeft, setPaddingLeft] = useState(10);
  const [focused, setFocused] = useState("none");
  const [focusedSolutionCard, setFocusedSolutionCard] = useState("none");

  useEffect(() => {
    i18n.changeLanguage(lang || "fr");
  }, [lang, i18n]);

  useEffect(() => {
    setPaddingLeft(Math.floor((screenSize.width / 2 - 600) / 2));
  }, [screenSize]);

  useEffect(() => {
    startFocusInterval();
    return () => clearInterval(intervalIDRef.current);
  }, []);

  const startFocusInterval = useCallback(() => {
    let i = 0;

    const focusCard = () => {
      setFocusedSolutionCard(postopCards[i].id);
      if (i < 2) i++;
      else i = 0;
    };

    intervalIDRef.current = setInterval(() => {
      focusCard();
    }, 3000);
  }, []);

  const stopFocusInterval = useCallback(() => {
    clearInterval(intervalIDRef.current);
    intervalIDRef.current = null;
  }, []);

  return (
    <div className="body-font bg-white text-gray-700">
      <section id="platform" className="body-font bg-cortexx-900 text-gray-700">
        <div className="container mx-auto flex flex-col items-center justify-center pt-10 md:px-5 md:pt-20">
          <div className="mb-4 flex w-full flex-col px-5 md:mb-10">
            <h1 class="text-4xl font-extrabold tracking-tight text-cortexx-100 md:text-5xl">
              <span class="inline">{t("title.line_1")}</span>
              <span class="inline"> </span>
              <span class="inline text-cortexx-400">{t("title.line_2")}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="60"
                viewBox="0 0 960 960"
                width="20"
                class="inline fill-cortexx-400"
              >
                <path d="M400-320h160q17 0 28.5-11.5T600-360v-80h-80v40h-80v-160h80v40h80v-80q0-17-11.5-28.5T560-640H400q-17 0-28.5 11.5T360-600v240q0 17 11.5 28.5T400-320Zm80 240q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
              </svg>
            </h1>
          </div>
          <div class="flex h-auto w-full flex-col md:flex-row">
            <div class="hidden md:flex md:w-1/3">
              <div class="flex w-2/3 items-end justify-start pt-4 sm:flex-col">
                {preOpCards.map(({ name, opacity }) => (
                  <div
                    class={`mb-2 flex h-20 w-20 items-center justify-center rounded-xl bg-cortexx-1000 bg-opacity-50 ${opacity} sm:h-10 sm:w-60`}
                  >
                    <p class="text-xxs font-bold text-cortexx-50 sm:text-sm ">
                      {t(name)}
                    </p>
                  </div>
                ))}
              </div>
              <div class="flex h-96 w-80">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-20 50 180 400"
                  fill="none"
                  class="animate-dash"
                >
                  <g
                    stroke="#06d6a0"
                    stroke-dasharray="0.24 5.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  >
                    <path d="M153 212H1.383"></path>
                    <path
                      d="M153 224H82.057a24 24 0 00-16.075 6.178L39.85 253.749a24 24 0 01-16.075 6.179H1"
                      opacity="0.9"
                    ></path>
                    <path
                      d="M153 236H93.844a24 24 0 00-19.969 10.687l-33.75 50.626A24 24 0 0120.155 308H1"
                      opacity="0.6"
                    ></path>
                    <path
                      d="M153 200H82.057a24 24 0 01-16.075-6.178L39.85 170.251a24 24 0 00-16.075-6.179H1"
                      opacity="0.9"
                    ></path>
                    <path
                      d="M153 188H93.844a24 24 0 01-19.969-10.687l-33.75-50.626A24 24 0 0020.155 116H1"
                      opacity="0.6"
                    ></path>
                  </g>
                </svg>
              </div>
            </div>
            <div class="mx-auto md:hidden">
              <div class="flex flex-wrap items-end justify-center">
                {preOpCards.map(({ name, opacity }) => (
                  <div
                    class={`mx-1 my-1 flex h-10 w-1/4 items-center justify-center rounded-lg bg-cortexx-1000 bg-opacity-50 text-center md:mb-2 md:${opacity} sm:h-10 sm:w-60`}
                  >
                    <p class="text-xxs font-bold text-cortexx-50 sm:text-sm ">
                      {t(name)}
                    </p>
                  </div>
                ))}
              </div>
              <div class="mx-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 83"
                  fill="none"
                  class="animate-dash"
                >
                  <g
                    class="input-lines-7-mobile_svg__scheme-dash-animated"
                    stroke="#06d6a0"
                    stroke-dasharray="0.24 5.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    mask="url(#input-lines-7-mobile_svg__fade_1)"
                  >
                    <path stroke="transparent" d="M10 56V0"></path>
                    <path d="M124 81V62.074a16 16 0 00-12.436-15.598L28.436 27.482A16 16 0 0116 11.884V1"></path>
                    <path d="M136 81.08V55.708a20 20 0 00-13.856-19.033L74.85 21.437A16 16 0 0163.765 6.211V1"></path>
                    <path d="M148 81V49.028a24 24 0 00-13.769-21.71l-13.051-6.15A16 16 0 01112 6.694V1M160 80V0M172 81V49.028a24 24 0 0113.769-21.71l13.051-6.15A16 16 0 00208 6.694V1"></path>
                    <path d="M184.088 81.08V55.708a20 20 0 0113.856-19.033l47.206-15.238a16 16 0 0011.085-15.226V1"></path>
                    <path d="M196 81V62.074a16 16 0 0112.436-15.598l83.128-18.994A16 16 0 00304 11.884V1"></path>
                  </g>
                  <defs>
                    <linearGradient
                      id="input-lines-7-mobile_svg__gradient_1"
                      y2="1"
                      x2="0"
                    >
                      <stop offset="0" stop-color="#fff"></stop>
                      <stop offset="0.8" stop-color="#fff"></stop>
                      <stop
                        offset="1"
                        stop-color="#fff"
                        stop-opacity="0"
                      ></stop>
                    </linearGradient>
                    <mask
                      id="input-lines-7-mobile_svg__fade_1"
                      maskContentUnits="objectBoundingBox"
                    >
                      <path
                        fill="url(#input-lines-7-mobile_svg__gradient_1)"
                        d="M0 0h2v1H0z"
                      ></path>
                    </mask>
                  </defs>
                </svg>
              </div>
            </div>
            <div class="mx-auto mb-8 flex h-40 w-full -translate-y-6 justify-center bg-cortexx-900 pt-10 md:w-80 md:translate-y-0 md:items-start md:pt-6">
              {cards.map((_card) => (
                <DeepSurgeryAngledCard
                  {..._card}
                  title={t(_card.title)}
                  corpusText={t(_card.corpusText)}
                  cardName={_card.name}
                  focused={focused}
                  setFocused={setFocused}
                />
              ))}
            </div>
            <div class="hidden flex-col items-center justify-center md:flex md:flex-row">
              <div class="flex h-96 w-40">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="30 50 180 400"
                  fill="none"
                  class="animate-dashBack"
                >
                  <g
                    stroke="#db81b8"
                    stroke-dasharray="0.24 5.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  >
                    <path d="M-24 212h176M-24 188h86.816a24 24 0 0022.72-16.268l21.598-63.464A24 24 0 01129.854 92H152M-24 236h86.771a24 24 0 0122.74 16.324l21.387 63.352A23.999 23.999 0 00129.637 332H152"></path>
                    <path
                      d="M-24 80h176M-24 340h176"
                      stroke="transparent"
                    ></path>
                  </g>
                </svg>
              </div>
            </div>
            <div class="h-auto w-full items-center justify-center md:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 80"
                fill="none"
                class="animate-dashBack"
              >
                <g
                  class="output-lines-3-curved-mobile_svg__scheme-dash-animated"
                  stroke="#db81b8"
                  stroke-dasharray="0.24 5.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  mask="url(#output-lines-3-curved-mobile_svg__fade_2)"
                >
                  <path stroke="transparent" d="M10 81V1"></path>
                  <path d="M136 1v26.825a16 16 0 01-13.059 15.727L61.059 55.123A16 16 0 0048 70.85V81M160 1v80M184 2.111v26.004a16 16 0 0013.058 15.727l61.883 11.572A16 16 0 01272 71.141V81"></path>
                </g>
                <defs>
                  <linearGradient
                    id="output-lines-3-curved-mobile_svg__gradient_2"
                    y2="1"
                    x2="0"
                  >
                    <stop offset="0" stop-color="#fff"></stop>
                    <stop offset="0.8" stop-color="#fff"></stop>
                    <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
                  </linearGradient>
                  <mask
                    id="output-lines-3-curved-mobile_svg__fade_2"
                    maskContentUnits="objectBoundingBox"
                  >
                    <path
                      fill="url(#output-lines-3-curved-mobile_svg__gradient_2)"
                      d="M0 0h2v1H0z"
                    ></path>
                  </mask>
                </defs>
              </svg>
            </div>
            <div class="flex flex-col items-center justify-center md:w-1/3 md:flex-row">
              <div class="mb-8 flex items-start justify-start md:flex-col">
                {postopCards.map((card) => (
                  <SolutionCard
                    card={{
                      ...card,
                      name: t(card.name),
                      description: t(card.description),
                    }}
                    focused={focusedSolutionCard}
                    setFocused={(id) => {
                      stopFocusInterval();
                      setFocusedSolutionCard(id);
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="mt-10 flex justify-center">
        <h1 class="text-3xl font-extrabold tracking-tight text-cortexx-900 md:text-5xl">
          {t("how_it_works")}
        </h1>
      </div>
      <section
        id="data_collection"
        class={`body-font container mx-auto flex h-auto flex-col bg-white px-5 pt-10 sm:flex-row md:pt-20`}
      >
        <FunctionsCard
          title={t("data_collection.title")}
          color={"to-cortexx-300"}
        >
          <div class="my-2 flex h-auto flex-col text-justify md:flex-row">
            <div class="flex flex-col pt-4 md:w-1/2">
              <div class="mb-4 h-auto px-1 text-lg text-cortexx-1000">
                {t("data_collection.text.line_1")}
              </div>
              <div class="h-auto rounded-md bg-slate-200 p-6 text-lg text-cortexx-1000 md:p-8">
                {t("data_collection.text.line_2")}
              </div>
            </div>
            <div class="mx-auto flex flex-row items-center justify-center pt-8 md:w-1/2">
              <div class="flex" style={{ height: "21rem" }}>
                <img
                  class="rounded object-cover object-center pr-8"
                  alt="screen-summary-sumo-app"
                  src={"../img/sumoapp/summary.png"}
                />
                <img
                  class="rounded object-cover object-center"
                  alt="screen-summary-sumo-app"
                  src={"../img/sumoapp/history.png"}
                />
              </div>
            </div>
          </div>
        </FunctionsCard>
      </section>
      <section
        id="storage"
        class={`body-font container mx-auto flex h-auto flex-col bg-white px-5 pt-8 sm:flex-row md:pt-20`}
      >
        <FunctionsCard title={t("storage.title")} color={"to-cortexx-400"}>
          <div class="my-2 flex h-auto flex-col text-justify md:flex-row">
            <div class="flex flex-col pt-4 md:w-1/2">
              <div class="mb-4 h-auto px-1 text-lg text-cortexx-1000">
                {t("storage.text.line_1")}
              </div>
              <div class="h-auto rounded-md bg-slate-200 p-6 text-lg text-cortexx-1000 md:p-8">
                {t("storage.text.line_2")}
              </div>
            </div>
            <div class="mx-auto flex flex-row items-center justify-center pt-8 md:w-1/2">
              <div class="mx-auto flex md:h-72">
                <img
                  class="rounded object-cover object-center"
                  alt="server-hds"
                  src={"../img/sumoapp/server_HDS.png"}
                />
              </div>
            </div>
          </div>
        </FunctionsCard>
      </section>
      <section
        id="analysis"
        class={`body-font container mx-auto flex h-auto flex-col bg-white px-5 sm:flex-row md:pt-20`}
      >
        <FunctionsCard title={t("analysis.title")} color={"to-cortexx-800"}>
          <div class="my-2 flex h-auto flex-col text-justify md:flex-row">
            <div class="flex flex-col pt-4 md:w-1/2">
              <div class="mb-4 h-auto px-1 text-lg text-cortexx-1000">
                {t("analysis.text.line_1")}
              </div>
              <div class="h-auto rounded-md bg-slate-200 p-6 text-lg text-cortexx-1000 md:p-8">
                {t("analysis.text.line_2")}
              </div>
            </div>
            <div class="mx-auto flex flex-row items-center justify-center pt-8 md:w-1/2">
              <div class="mx-auto flex md:h-72">
                <img
                  class="rounded object-cover object-center"
                  alt="analysis"
                  src={"../img/sumoapp/analysis.png"}
                />
              </div>
            </div>
          </div>
        </FunctionsCard>
      </section>
      <section
        id="prediction"
        class={`body-font container mx-auto flex h-auto flex-col bg-white px-5 pt-10 sm:flex-row md:pt-20`}
      >
        <FunctionsCard title={t("prediction.title")} color={"to-cortexx-200"}>
          <div class="my-2 flex h-auto flex-col text-justify md:flex-row">
            <div class="flex flex-col pt-4 md:w-1/2">
              <div class="mb-4 h-auto px-1 text-lg text-cortexx-1000">
                {t("prediction.text.line_1")}
              </div>
              <div class="h-auto rounded-md bg-slate-200 p-6 text-lg text-cortexx-1000 md:p-8">
                {t("prediction.text.line_2")}
              </div>
            </div>
            <div class="mx-auto flex flex-row items-center justify-center pt-8 md:w-1/2">
              <div class="mx-auto flex md:h-72">
                <img
                  class="rounded object-cover object-center"
                  alt="prediction"
                  src={"../img/sumoapp/prediction.png"}
                />
              </div>
            </div>
          </div>
        </FunctionsCard>
      </section>
    </div>
  );
};

export default Platform;
