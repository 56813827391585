/* This example requires Tailwind CSS v2.0+ */
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import React, { Fragment } from "react";

const LanguageDropdown = (props) => {
  const { setLanguage, value } = props;
  return (
    <Menu
      as="div"
      className="relative inline-block text-left md:ml-8 md:space-x-8"
    >
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md border border-white bg-cortexx-900 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:opacity-20 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          {value || "Lang"}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 w-14 origin-top-right rounded-md bg-gray-100 px-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none md:mt-1">
          <Menu.Item onClick={() => setLanguage("en")}>
            <img
              src="./img/united-kingdom-2.png"
              className="UK-Flag"
              alt="uk-flag"
            />
          </Menu.Item>
          <Menu.Item onClick={() => setLanguage("fr")}>
            <img
              src="./img/france-2.png"
              className="France-Flag"
              alt="fr-flag"
            />
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default LanguageDropdown;
