import React from "react";
import useScreenSize from "../utils/useScreenSize";

const translations = {
  0.175: "-translate-x-[325px]",
  0.2: "-translate-x-[300px]",
  0.225: "-translate-x-[275px]",
  0.25: "-translate-x-[250px]",
  0.5: "-translate-x-[225px]",
  1: "-translate-x-[200px]",
  1.5: "-translate-x-[175px]",
  2: "-translate-x-[150px]",
  2.5: "-translate-x-[125px]",
  3: "-translate-x-[100px]",
  3.5: "-translate-x-[75px]",
  4: "-translate-x-[50px]",
  4.5: "-translate-x-[25px]",
  0: "-translate-x-[0px]",
  0.525: "translate-x-[25px]",
  5: "translate-x-[50px]",
  5.5: "translate-x-[75px]",
  6: "translate-x-[100px]",
  7: "translate-x-[150px]",
};

const translationsLg = {
  "data-collection": {
    none: "0.225",
    "data-collection": "0.25",
    storage: "0.175",
    analysis: "0.175",
    prediction: "0.175",
  },
  storage: {
    none: "1",
    "data-collection": "3.5",
    storage: "1",
    analysis: "0.2",
    prediction: "0.2",
  },
  analysis: {
    none: "2.5",
    "data-collection": "4",
    storage: "4.5",
    analysis: "1.5",
    prediction: "0.225",
  },
  prediction: {
    none: "4",
    "data-collection": "4.5",
    storage: "0",
    analysis: "0",
    prediction: "3",
  },
};

const translationsSm = {
  "data-collection": {
    none: "1",
    "data-collection": "1.5",
    storage: "0.5",
    analysis: "0.5",
    prediction: "0.5",
  },
  storage: {
    none: "2.5",
    "data-collection": "4.5",
    storage: "2.5",
    analysis: "1",
    prediction: "1",
  },
  analysis: {
    none: "4",
    "data-collection": "0",
    storage: "0.525",
    analysis: "3",
    prediction: "1.5",
  },
  prediction: {
    none: "0.525",
    "data-collection": "0.525",
    storage: "5",
    analysis: "5",
    prediction: "4.5",
  },
};

const AngledCard = (props) => {
  const rotations = {
    y: {
      0: "rotate-y-[0deg]",
      65: "rotate-y-[65deg]",
      45: "rotate-y-[45deg]",
    },
    x: {
      0: "rotate-x-[0deg]",
      5: "rotate-x-[5deg]",
    },
  };

  return (
    <div
      class={`absolute flex aspect-square h-40 w-40 overflow-hidden sm:h-52 sm:w-52 ${
        rotations.x[props.movements.rotations.x]
      } ${rotations.y[props.movements.rotations.y]} rounded-[32px] ${
        props.background
      } scale-0.95 aspect-square p-2 opacity-95 transition duration-700 ease-in-out transform`}
    >
      {props.children}
    </div>
  );
};

const DeepSurgeryAngledCard = (props) => {
  const { focused, setFocused } = props;
  const screenSize = useScreenSize();

  return (
    <div
      class={`${
        translations[
          screenSize.width > 620
            ? translationsLg[props.cardName][focused]
            : translationsSm[props.cardName][focused]
        ]
      } duration-300 perspective-1200 translate-z-[200px]`}
      onMouseOver={() => screenSize.width > 620 && setFocused(props.cardName)}
      onMouseOut={() => screenSize.width > 620 && setFocused("none")}
      onClick={() => screenSize.width < 620 && setFocused(props.cardName)}
    >
      <AngledCard
        background={`bg-gradient-to-tl from-cortexx-600 ${props.color} ${
          focused === props.cardName
            ? "boder-cortexx-100 border"
            : "shadow-[-2px_0px_0px_#e5ecf1]"
        }`}
        movements={{
          rotations: {
            y: focused === props.cardName ? 0 : focused !== "none" ? 65 : 45,
            x: focused === props.cardName ? 0 : 5,
          },
        }}
      >
        <div class="flex flex-col">
          <div class="flex flex-1">
            <p class="title-font sm:text-l ml-4 mr-4 text-xs font-extrabold tracking-tight text-cortexx-100">
              {props.title}
            </p>
          </div>
          <div
            class={`transition-all duration-700 ${
              focused === props.cardName ? "opacity-100" : "opacity-0"
            }`}
          >
            <p class="ml-4 mr-4 text-xxs font-normal tracking-tight text-cortexx-100 sm:text-xs">
              {props.corpusText}
            </p>
            <a
              class="ml-4 mr-4 mt-2 text-xxs font-extrabold tracking-tight text-cortexx-100 sm:text-xxs"
              href={props.link}
            >
              en savoir plus →
            </a>
          </div>
          <div class="mb-2 ml-4 flex flex-1 items-end justify-start">
            {props.icon}
          </div>
        </div>
      </AngledCard>
    </div>
  );
};

export default DeepSurgeryAngledCard;
