import React from "react"

const PrivacyPro = () => {
  return (
    <div className="w-full lg:p-10 p-5">
      <body>
        <header>
          <p className="lg:w1/2 w-full leading-relaxed text-gray-600 my-4 font-bold lg:text-4xl">
            Politique de Confidentialité de SUMO® « CORTEXX MEDICAL
            INTELLIGENCE»
          </p>
        </header>

        <nav>
          <p
            id="contents"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 my-4 font-bold lg:text-2xl"
          >
            Sommaire
          </p>

          <ul>
            <li>
              <a
                href="#about"
                className="lg:w-3/4 w-full leading-snug text-blue-500 lg:text-base font-bold lg:text-sm"
              >
                A propos
              </a>
            </li>

            <li>
              <a
                href="#application-field"
                className="lg:w-3/4 w-full leading-snug text-blue-500 lg:text-base font-bold lg:text-sm"
              >
                Champ d'application
              </a>
            </li>

            <li>
              <a
                href="#type-of-data"
                className="lg:w-3/4 w-full leading-snug text-blue-500 lg:text-base font-bold lg:text-sm"
              >
                Type de données détenues
              </a>
            </li>

            <li>
              <a
                href="#data-storage-duration"
                className="lg:w-3/4 w-full leading-snug text-blue-500 lg:text-base font-bold lg:text-sm"
              >
                Durée de stockage des données
              </a>
              <ul>
                <li>
                  <a
                    href="#data-storage-duration-1"
                    className="lg:w-3/4 w-full leading-snug text-blue-500 lg:text-base font-bold lg:text-sm mx-2"
                  >
                    Généralités
                  </a>
                </li>
                <li>
                  <a
                    href="#data-storage-duration-4"
                    className="lg:w-3/4 w-full leading-snug text-blue-500 lg:text-base font-bold lg:text-sm mx-2"
                  >
                    Cas particuliers
                  </a>
                </li>
              </ul>
            </li>

            <li>
              <a
                href="#users-rights"
                className="lg:w-3/4 w-full leading-snug text-blue-500 lg:text-base font-bold lg:text-sm"
              >
                Droits des utilisateurs
              </a>
            </li>

            <li>
              <a
                href="#data-destination"
                className="lg:w-3/4 w-full leading-snug text-blue-500 lg:text-base font-bold lg:text-sm"
              >
                Destinataires des données
              </a>
            </li>

            <li>
              <a
                href="#data-transfer"
                className="lg:w-3/4 w-full leading-snug text-blue-500 lg:text-base font-bold lg:text-sm"
              >
                Transferts des données
              </a>
            </li>

            <li>
              <a
                href="#links-ext"
                className="lg:w-3/4 w-full leading-snug text-blue-500 lg:text-base font-bold lg:text-sm"
              >
                Liens externes
              </a>
            </li>

            <li>
              <a
                href="#data-security"
                className="lg:w-3/4 w-full leading-snug text-blue-500 lg:text-base font-bold lg:text-sm"
              >
                Sécurité des données
              </a>
            </li>

            <li>
              <a
                href="#confidentiality-version"
                className="lg:w-3/4 w-full leading-snug text-blue-500 lg:text-base font-bold lg:text-sm"
              >
                Versions du présent document
              </a>
            </li>

            <li>
              <a
                href="#contact"
                className="lg:w-3/4 w-full leading-relaxed text-blue-500 lg:text-base font-bold lg:text-sm"
              >
                Contact
              </a>
            </li>
          </ul>
        </nav>

        <article>
          <h2
            id="about"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 my-4 font-bold lg:text-2xl"
          >
            A propos
          </h2>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            L’application pour terminal mobile (Interface SUMO®) et navigateur
            web (SUMO® Pro) dénommée « SUMO®» (ci-après l’«SUMO®») est éditée
            par la société CORTEXX MEDICAL INTELLIGENCE (ci-après «CortexxMI»).
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            CortexxMI prend très au sérieux le respect de la vie privée et de la
            protection des données à caractère personnel.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            C’est la raison pour laquelle, CortexxMI, en tant que responsable du
            traitement, s’emploie et s’engage à protéger la vie privée et les
            données à caractère personnel des Utilisateurs en conformité avec
            les exigences légales et notamment le Règlement n°2016/679 du 27
            avril 2016 relatif à la protection des personnes physiques à l'égard
            du traitement des données à caractère personnel et à la libre
            circulation de ces données (ci-après « Règlement Général sur la
            Protection sur la Protection des Données » ou « RGPD ») et la Loi
            n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et
            aux libertés (dite « Loi Informatique et Libertés »), (ensemble la «
            Réglementation Applicable »).
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            CortexxMI peut être contactée par courrier électronique à l’adresse
            électronique suivante : dpo@cortexxmi.com mais également par
            courrier postal à l’adresse postale suivante : 156 Boulevard
            Haussmann 75008 Paris.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            La présente politique de confidentialité (ci-après la « Politique de
            Confidentialité ») a pour objet d’informer au mieux les Utilisateurs
            de SUMO® de la manière dont CortexxMI protège les données à
            caractère personnel qu’elle collecte dans le cadre du téléchargement
            et de l’utilisation de SUMO® et sur les engagements pris par
            CortexxMI afin de veiller au respect des données à caractère
            personnel des Utilisateurs.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            En fournissant des données à caractère personnel dans le cadre de
            l’utilisation de SUMO®, l’Utilisateur reconnaît avoir pris
            connaissance des termes de la présente Politique de Confidentialité.
            Les termes commençant par une majuscule et non définis dans les
            présentes ont la signification qui leur est donnée dans les CGU.
          </p>
          <nav id="to-top" className="text-blue-500 lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
          <h2
            id="application-field"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 my-4 font-bold lg:text-2xl"
          >
            Quel est le champ d’application de la présente politique de
            confidentialité?
          </h2>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            La Politique de Confidentialité s’applique à toute personne physique
            majeure de plus de 18 ans, inscrites et titulaires d’un compte
            valide sur SUMO® Pro (ci-après le ou les « Utilisateur(s) »).
          </p>
          <nav id="to-top" className="text-blue-500 lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
          <h2
            id="type-of-data"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 my-4 font-bold lg:text-2xl"
          >
            Quelles sont les données à caractère personnel que CortexxMI est
            susceptible de détenir sur l’utilisateur?
          </h2>
          <ol type="1">
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-600 lg:text-base my-4">
                Comment CortexxMI utilise ces données ?
              </p>
            </li>
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-600 lg:text-base my-4">
                Sur quelle base juridique et/ou quelle exception repose le
                traitement des données à caractère personnel et des données
                sensibles ?
              </p>
            </li>
          </ol>
          <hr />
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            L’identité, incluant son nom et son prénom.
          </p>
          <ol type="1">
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                Gérer la procédure de création du compte Utilisateur, le
                processus de connexion à SUMO® et plus généralement le compte
                Utilisateur
              </p>
            </li>
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                Ces traitements sont nécessaires à l’exécution du contrat entre
                CortexxMI et l’Utilisateur c’est-à-dire qu’ils sont nécessaires
                pour fournir à l’Utilisateur le service proposé par CortexxMI
              </p>
            </li>
          </ol>
          <hr />
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Les coordonnées de l’Utilisateur : adresse de courrier électronique,
            numéro de téléphone.
          </p>
          <ol type="1">
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                Envoyer des messages d’informations (notamment envoi
                d’informations et de guides médicaux) et permettre la mise en
                relation du patient avec le médecin ou professionnel de santé
              </p>
            </li>
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                Ce traitement est nécessaire à la poursuite des intérêts
                légitimes de CortexxMI, afin d’optimiser SUMO® ses Services,
                comprendre leur efficacité, veiller à ce que l’Utilisateur
                bénéficie de l’expérience la plus appropriée.
              </p>
            </li>
          </ol>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Le traitement bénéficie ainsi tant à l’Utilisateur qu’à CortexxMI
            sans porter atteinte aux intérêts, droits et libertés fondamentaux
            des Utilisateurs.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Lorsque la Réglementation Applicable l’exige, CortexxMI collecte le
            consentement de l’Utilisateur qui peut en tout état de cause
            demander à tout moment à ne plus recevoir d’informations par
            courrier, SMS ou email en se désabonnant via le lien présent dans le
            bas de page des communications qu’il reçoit de la part de CortexxMI
            ou en envoyant un email à l’adresse mail suivante:
            dpo@cortexxmi.com.
          </p>
          <hr />
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Le numéro ADELI, sa spécialité médicale et le lieu d’exercice de son
            activité professionnelle principale .
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            De consulter les résultats du traitement autonome des données
            réalisées par SUMO® qui figurent sur son Compte Utilisateur,
            d’interpréter à distance ces données et, le cas échéant, de prendre
            des décisions relatives à la prise en charge médicale du patient.
          </p>
          <ol type="1">
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                Ces traitements sont nécessaires à l’exécution du contrat entre
                CortexxMI et l’Utilisateur c’est-à-dire qu’ils sont nécessaires
                pour fournir à l’Utilisateur le service proposé par CortexxMI.
              </p>
            </li>
          </ol>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Néanmoins, dans la mesure où les données de santé sont des données
            auxquelles la Réglementation Applicable accorde une importance et
            une protection toute particulière, CortexxMI doit se fonder sur
            l’une des exceptions prévues par la Réglementation Applicable pour
            pouvoir traiter ce type de données. Toutes les données de santé
            traitées et stockées sur nos serveurs sont anonymisées et utilisées
            pour entraîner notre technologie à devenir plus précise dans le
            pronostic de la qualité de vie en post-opératoire.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            C’est la raison pour laquelle CortexxMI demande à l’Utilisateur son
            consentement pour traiter ces données lorsque l’Utilisateur utilise
            SUMO® à des fins d’auto-suivi de son épisode soin en chirurgie du
            rachis.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            L’Utilisateur peut à tout moment retirer son consentement en
            supprimant ses données de santé directement sur son profil ou en
            contactant CortexxMI à l’adresse mail suivante : dpo@cortexxmi.com
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            CortexxMI attire toutefois l’attention de l’Utilisateur quant au
            fait qu’il ne pourra pas bénéficier de l’intégralité des Services si
            les données de santé de l’Utilisateur ne peuvent pas être traitées.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Lorsque l’Utilisateur utilise SUMO® à des fins d’assistance
            diagnostic ou pronostic de, suivi de l’épisode chirurgical avec un
            médecin ou un professionnel de santé, le consentement de
            l’Utilisateur n’est pas nécessaire dans la mesure où SUMO® est
            utilisée par l’Utilisateur dans le cadre d’un dispositif de
            d’assistance médicale.
          </p>
          <hr />
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Lorsque l’Utilisateur utilise les auto-questionnaires guidés ou non
            et outils de chargement des rapports médicaux en photo de SUMO®
          </p>
          <ol type="1">
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                Permettre aux Utilisateurs d’importer, de renseigner, de
                centraliser, de consulter et de gérer les Données de Suivi.
                Optimiser/améliorer SUMO®.
              </p>
            </li>
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                Ces traitements sont nécessaires à l’exécution du contrat entre
                CortexxMI et l’Utilisateur c’est-à-dire qu’ils sont nécessaires
                pour fournir à l’Utilisateur le service proposé par CortexxMI.
              </p>
            </li>
          </ol>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Le traitement de ces données à des fins d’optimisation et
            d’amélioration de SUMO® est quant à lui nécessaire à la poursuite
            des intérêts légitimes de CortexxMI afin que CortexxMI puisse
            adapter SUMO® aux profils de chaque Utilisateur.
          </p>
          <hr />
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Les données relatives à l’appareil permettant d’utiliser SUMO® :
            informations techniques concernant les caractéristiques et données
            de fonctionnement de l’appareil de l’Utilisateur, l’opérateur, le
            système d’exploitation, l’adresse IP.
          </p>
          <ol type="1">
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                Gérer SUMO® et ses fonctions opérationnelles, améliorer et
                développer de nouvelles fonctionnalités en fonction de
                l’utilisation, optimiser l’affichage sur SUMO® en fonction des
                préférences choisis par l’Utilisateur
              </p>
            </li>
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                Ces traitements sont nécessaires à la poursuite des intérêts
                légitimes (intérêts économiques) de CORTEXX MEDICAL INTELLIGENCE
                afin de veiller à proposer à l’Utilisateur une SUMO® qui
                fonctionne correctement, de résoudre tout problème technique,
                d’assurer la protection et la sécurité de SUMO®. Le traitement
                bénéficie ainsi tant à l’Utilisateur qu’à CORTEXX MEDICAL
                INTELLIGENCE sans porter atteinte aux intérêts, droits et
                libertés fondamentaux des Utilisateurs.
              </p>
            </li>
          </ol>
          <hr />
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Logs de connexion à SUMO® et tags correspondants à l’utilisation de
            chacune des fonctionnalités de SUMO®
          </p>
          <ol type="1">
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                Gérer SUMO® et ses fonctions opérationnelles, améliorer et
                développer de nouvelles fonctionnalités en fonction de
                l’utilisation, optimiser l’affichage sur SUMO® en fonction des
                préférences choisis par l’Utilisateur
              </p>
            </li>
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                Ces traitements sont pour certains nécessaires à l’exécution du
                contrat entre CortexxMI et l’Utilisateur c’est-à-dire qu’ils
                sont nécessaires pour fournir à l’Utilisateur le service proposé
                par CortexxMI et pour d’autres nécessaires à la poursuite des
                intérêts légitimes (intérêts économiques) de CortexxMI afin de
                veiller à proposer à l’Utilisateur SUMO® qui fonctionne
                correctement, de résoudre tout problème technique, d’assurer la
                protection et la sécurité de SUMO®.
              </p>
            </li>
          </ol>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Le traitement bénéficie ainsi tant à l’Utilisateur qu’à CortexxMI
            sans porter atteinte aux intérêts, droits et libertés fondamentaux
            des Utilisateurs.
          </p>
          <hr />
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Données d’identification de l’Utilisateur : nom, prénom, adresse
            email
          </p>
          <ol type="1">
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                Répondre aux demandes des Utilisateurs et communiquer avec eux,
                notamment pour leur demander leur avis sur le service fourni par
                CortexxMI et les changements et évolutions importantes des
                Services proposés par CortexxMI. Faire application de la
                législation, de la réglementation en vigueur et assurer sa
                défense et/ou défendre ses intérêts en cas de contentieux.
              </p>
            </li>
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                Ces traitements sont nécessaires à la poursuite des intérêts
                légitimes de CortexxMI (intérêts économiques) afin d’améliorer
                les Services proposés à l’Utilisateur, mieux comprendre les
                besoins et attentes des Utilisateurs. Le traitement bénéficie
                ainsi tant à l’Utilisateur qu’à CortexxMI sans porter atteinte
                aux intérêts, droits et libertés fondamentaux des Utilisateurs.
                Le traitement relatif à l’application de la législation et la
                réglementation en vigueur repose sur les obligations légales qui
                peuvent reposer sur CortexxMI.
              </p>
            </li>
          </ol>
          <hr />
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Données collectées et traitées dans le cadre de l’utilisation de la
            messagerie spécifique.
          </p>
          <ol type="1">
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                Gérer SUMO® et ses fonctions opérationnelles et notamment
                fournir les services de messagerie en permettant à l’Utilisateur
              </p>
              <ol type="i">
                <li>
                  <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                    d’échanger directement avec son médecin ou professionnel de
                    santé en charge de pratiquer les actes d’assistance médicale
                    dans le cadre du dispositif d’assistance médicale;
                  </p>
                </li>
                <li>
                  <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                    d’adresser un message personnel directement à un autre
                    Utilisateur.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                Ce traitement est nécessaire à l’exécution du contrat entre
                CortexxMI et l’Utilisateur c’est-à-dire qu’ils sont nécessaires
                pour fournir à l’Utilisateur le service proposé par CortexxMI.
              </p>
            </li>
          </ol>
          <nav id="to-top" className="text-blue-500 lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
          <h2
            id="data-storage-duration"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 my-4 font-bold lg:text-2xl"
          >
            Combien de temps CortexxMI conserve-t-elle les données à caractère
            personnel des utilisateurs?
          </h2>
          <h3
            id="data-storage-duration-1"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 my-6 font-bold lg:text-xl"
          >
            Durées de conservation applicables de manière générale
          </h3>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            CortexxMI conserve les données à caractère personnel pour toute la
            durée d’activation du compte personnel de l’Utilisateur.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            L’Utilisateur a la possibilité de fermer son espace personnel à tout
            moment en adressant une demande par mail à CortexxMI à l’adresse
            suivante : dpo@cortexxmi.com ou en supprimant directement son compte
            depuis son espace personnel Utilisateur.
          </p>
          <h3
            id="data-storage-duration-4"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 my-6 font-bold lg:text-xl"
          >
            Durées de conservation spécifiques à certains types de données à
            caractère personnel
          </h3>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Les données relatives à la géolocalisation des Utilisateurs ne sont
            pas conservées par CortexxMI.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Les données de fréquentation brutes associées à l’identifiant de
            l’Utilisateur sont conservées pendant une durée de 13 mois.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Les données relatives à l’adresse IP des Utilisateurs sont également
            conservées pendant 13 mois à compter de la première connexion de
            l’Utilisateur sur SUMO®.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Les autres données de connexion des Utilisateurs sont conservées
            pendant une durée de 6 mois.
          </p>
          <nav id="to-top" className="text-blue-500 lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
          <h2
            id="users-rights"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 my-4 font-bold lg:text-2xl"
          >
            Quels sont les droits des utilisateurs sur leurs données à caractère
            personnel ?
          </h2>
          <ol type="i">
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                un droit à l’information : l’Utilisateur a le droit d’obtenir
                des informations claires, transparentes, compréhensibles et
                aisément accessibles sur la manière dont CortexxMI utilise ses
                données à caractère personnel et sur ses droits. C’est la raison
                pour laquelle CortexxMI a rédigé la présente Politique de
                Confidentialité.
              </p>
            </li>
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                un droit d’accès : l’Utilisateur a le droit d’accéder aux
                données à caractère personnel dont CortexxMI dispose sur lui
                (sous réserve que la demande ne soit pas manifestement infondée
                ou excessive, notamment en raison de son caractère répétitif),
                et d’en obtenir une copie.
              </p>
            </li>
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                un droit de rectification : l’Utilisateur a le droit d’exiger
                que ses données à caractère personnel soient rectifiées si elles
                sont inexactes ou périmées et/ou qu’elles soient complétées si
                elles sont incomplètes (sous réserve que la demande ne soit pas
                manifestement infondée ou excessive, notamment en raison de son
                caractère répétitif).
              </p>
            </li>
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                un droit à l’effacement : dans certains cas, l’Utilisateur a le
                droit d’obtenir l’effacement ou la suppression de ses données à
                caractère personnel. Ceci n’est pas un droit absolu, dans la
                mesure où CortexxMI peut être contraint de conserver les données
                à caractère personnel de l’Utilisateur pour des motifs légaux ou
                légitimes.
              </p>
            </li>
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                un droit à la limitation du traitement : l’Utilisateur a le
                droit de demander à ce que le traitement de ses données à
                caractère personnel soit limité, de sorte que CortexxMI peut
                conserver ces données, mais ne peut ni les utiliser ni les
                traiter. Ce droit s’applique dans des circonstances
                particulières, à savoir: Dans les cas où l’exactitude des
                données à caractère personnel est contestée par l’Utilisateur.
                Le traitement est alors limité pendant une durée permettant à
                CortexxMI de vérifier l’exactitude des données à caractère
                personnel; Dans les cas où le traitement est illicite et
                l’Utilisateur s’oppose à leur effacement et exige à la place la
                limitation de leur utilisation; Dans les cas où CortexxMI n’a
                plus besoin des données à caractère personnel aux fins du
                traitement mais que celles-ci sont encore nécessaires à
                l’Utilisateur pour la constatation, l’exercice ou la défense de
                droits en justice; Dans les cas où l’Utilisateur s’est opposé au
                traitement fondé sur les intérêts légitimes poursuivis par
                CortexxMI pendant la vérification portant sur le point de savoir
                si les motifs légitimes poursuivis par CortexxMI prévalent sur
                ceux de la personne concernée ou non.
              </p>
            </li>
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                un droit d’opposition au traitement de ses données à caractère
                personnel lorsque le traitement est basé sur l’intérêt légitime
                de CortexxMI (cf ci-dessus le tableau figurant en Section 3 pour
                identifier lesdits traitements et données): l’Utilisateur peut à
                tout moment s’opposer au traitement de ses données à caractère
                personnel pour des raisons tenant à sa situation particulière, à
                moins que CortexxMI ne fasse valoir des motifs légitimes et
                impérieux pour le traitement de telles données qui prévalent sur
                les intérêts, droits et libertés de l’Utilisateur ou lorsque de
                telles données sont nécessaires pour la constatation, l’exercice
                ou la défense d’un droit en justice.
              </p>
            </li>
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                un droit de s’opposer à l’information directe : l’Utilisateur
                peut, à tout moment, se désinscrire ou s’opposer à la réception
                des messages d’information de CortexxMI. Il suffit simplement de
                cliquer sur le lien présent dans le bas de page des
                communications qu’il reçoit de la part de CortexxMI ou d’envoyer
                un message à l’adresse mail suivante: dpo@cortexxmi.com.
              </p>
            </li>
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                un droit de retirer son consentement à tout moment pour les
                traitements de données fondés sur le consentement ou lorsque le
                consentement est nécessaire pour pouvoir traiter des données de
                santé (cf ci-dessus le tableau figurant en Section 3 pour
                identifier lesdits traitements et données): l’Utilisateur peut,
                à tout moment, retirer son consentement au traitement de ses
                données si ce traitement est fondé sur le consentement ou si le
                consentement a été recueilli pour pouvoir traiter des données de
                santé. Le retrait de ce consentement ne porte pas atteinte à la
                licéité du traitement fondé sur le consentement effectué avant
                le retrait de celui-ci.
              </p>
            </li>
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                un droit à la portabilité des données : l’Utilisateur a le droit
                de recevoir les données à caractère personnel qui le concernent,
                qu’il a fourni à CortexxMI et qui sont présentes dans la base de
                données de CortexxMI, sous un format structuré, couramment
                utilisé et lisible par machine. Cela s’applique uniquement aux
                données que l’Utilisateur a fournies directement ou
                indirectement, lorsque le traitement est fondé (i) sur le
                consentement de l’Utilisateur ou sur l’exécution d’un contrat
                (cf ci-dessus le tableau figurant en Section 3 pour identifier
                lesdits traitements et données) et (ii) qu’il est effectué à
                l’aide de procédés automatisés.
              </p>
            </li>
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                un droit de définir des directives spéciales relatives à la
                conservation, à l’effacement et à la communication de vos
                données personnelles après son décès.
              </p>
            </li>
            <li>
              <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
                un droit d’introduire une réclamation auprès d’une autorité de
                contrôle : l’Utilisateur a le droit de saisir et d’introduire
                une réclamation auprès de l’autorité de protection des données
                de son pays (en France la CNIL) pour contester les pratiques de
                CortexxMI en matière de protection des données à caractère
                personnel et de respect de la vie privée. L’Utilisateur peut, à
                tout moment, exercer les droits mentionnés en adressant une
                demande à l’adresse mail suivante dpo@cortexxmi.com. ou à
                l’adresse postale suivante : CortexxMI SAS, 156 Boulevard
                Haussmann, 75008 Paris.
              </p>
            </li>
          </ol>
          <nav id="to-top" className="text-blue-500 lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
          <h2
            id="data-destination"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 my-4 font-bold lg:text-2xl"
          >
            Quels sont les destinataires des données à caractère personnel de
            l’utilisateur?
          </h2>
          <hr />
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            CortexxMI s’engage à conserver toutes les données à caractère
            personnel collectées via SUMO® et à ne les partager que dans
            certaines circonstances et conformément aux dispositions de la
            Réglementation Applicable.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            La communication des données à caractère personnel des Utilisateurs
            aux salariés de CortexxMI: Certaines des données à caractère
            personnel des Utilisateurs sont accessibles aux salariés de
            CortexxMI si ces personnes ont besoin d’y avoir accès pour
            l’exécution des finalités mentionnées c’est-à-dire pour fournir aux
            Utilisateurs les Services de façon appropriée.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Les services internes de CortexxMI qui peuvent avoir accès aux
            données à caractère personnel des Utilisateurs sont notamment le
            service informatique, le service assistance technique, le service de
            recherche et d’évaluation clinique.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            La communication des données à caractère personnel des Utilisateurs
            à des médecins ou des professionnels de santé: SUMO® permet au
            médecin ou autre professionnel de santé (i) adhérent au programme
            SUMO® Pro et (ii) expressément autorisé par l’Utilisateur à accéder
            et consulter les données de l’Utilisateur qui figurent sur son
            Compte Utilisateur, d’interpréter à distance ces données et, le cas
            échéant, de prendre des décisions relatives à la prise en charge
            médicale de cet Utilisateur.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            L’Utilisateur peut à tout moment modifier l’identité des médecins ou
            professionnels de santé qui ont accès à ces données.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            La communication des données à caractère personnel des Utilisateurs
            à des prestataires de services tiers: CortexxMI peut notamment
            donner un accès aux données à caractère personnel des Utilisateurs à
            des prestataires de services tiers, agissant en tant que
            sous-traitants pour exécuter des services relatifs à SUMO® et
            notamment les services d’hébergement, de stockage, d’analyses, de
            communication, de traitement de données, de gestion de bases de
            données ou encore de maintenance informatique.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Ces prestataires n’agissent que sur instructions de CortexxMI et
            n’auront accès aux données à caractère personnel des Utilisateurs
            que pour exécuter ces services susmentionnés et seront tenus aux
            mêmes obligations de sécurité et de confidentialité que CortexxMI.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Par ailleurs, les données à caractère personnel et les données de
            santé anonymisées de l’Utilisateur peuvent être partagées avec des
            tiers pour les motifs suivants : Dans le cadre de la recherche
            scientifique avec des personnes dûment autorisées et qui respectent
            les règles de déontologie applicables à leurs secteurs d’activités.
            Il n’y a aucun risque pour votre vie privée, car la sécurité des
            informations est régie conformément à la réglementation GDPR.
            Veuillez noter que vos réclamations de litiges en matière
            d’assurances ne seront pas affectées par cette étude, car les
            données ne seront pas analysées au niveau individuel.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Seules des données sous forme anonymisée leur sont transmises; Dans
            le cadre d’une fusion, acquisition ou vente de tout ou partie des
            actifs de la société, ce dont l’Utilisateur reconnaît avoir été
            informé; En réponse à une procédure judiciaire ou administrative de
            toute nature ou à des mesures d’application de la loi réclamées par
            les autorités compétentes; Pour se conformer à des obligations
            légales, pour protéger les droits et/ou la sûreté d’un individu,
            pour protéger les droits et la propriété de CortexxMI, y compris la
            nécessité de voir la présente Politique de Confidentialité et les
            Conditions Générales d’Utilisation respectées, et d’empêcher les
            problèmes de fraude, de sécurité ou techniques.
          </p>
          <nav id="to-top" className="text-blue-500 lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
          <h2
            id="data-transfer"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 my-4 font-bold lg:text-2xl"
          >
            Les données des utilisateurs sont-elles transférées vers des pays
            qui se trouvent en dehors de l’espace économique européen?
          </h2>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Afin de vous fournir des Services de meilleure qualité et notamment
            de résoudre les éventuels difficultés techniques 24 heures sur 24 et
            7 jours sur 7.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            CortexxMI réalisera cette opération de manière sécurisée et dans le
            respect de la Réglementation Applicable.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Les modèles de clauses contractuelles types sont accessibles à
            l’adresse suivante:
            <a
              href="https://www.cnil.fr/fr/les-clauses-contractuelles-types-de-la-commision-europeenne"
              className="lg:w-3/4 w-full leading-snug text-blue-500 lg:text-base font-bold lg:text-sm underline"
            >
              https://www.cnil.fr/fr/les-clauses-contractuelles-types-de-la-commision-europeenne
            </a>
          </p>
          <nav id="to-top" className="text-blue-500 lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
          <h2
            id="links-ext"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 my-4 font-bold lg:text-2xl"
          >
            Lien vers des applications et/ou site internet de tiers?
          </h2>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Pendant la navigation sur SUMO®, l’Utilisateur est susceptible de
            voir des contenus qui comportent des liens vers des Applications
            Mobiles et/ou sites internet de tiers.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            CortexxMI ne peut accéder ni contrôler les cookies ou les autres
            fonctions utilisées par les Applications Mobiles et/ou sites
            internet des tiers, et les procédures et traitements mis en œuvre
            par ces Applications Mobiles externes ne sont pas régies par la
            présente Politique de Confidentialité.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Par conséquent, il appartient à l’Utilisateur de contacter et/ou de
            consulter directement les conditions générales d’utilisation et
            politiques de confidentialité de ces Applications Mobiles et/ou
            sites internet de ces tiers pour obtenir des informations
            supplémentaires concernant leurs procédures de protection des
            données à caractère personnel.
          </p>
          <nav id="to-top" className="text-blue-500 lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
          <h2
            id="data-security"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 my-4 font-bold lg:text-2xl"
          >
            Quelles mesures de sécurité SUMO® prend-elle afin d’assurer la
            confidentialité et la sécurité des informations des utilisateurs?
          </h2>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            La sécurité des données à caractère personnel fait partie des
            priorités de CortexxMI.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Ainsi, CortexxMI s’engage à prendre toutes les mesures raisonnables
            sur le plan organisationnel et technique pour empêcher toute
            divulgation, utilisation, altération, perte accidentelle ou
            destruction des données à caractère personnel qu’un Utilisateur
            fournit.
          </p>
          <nav id="to-top" className="text-blue-500 lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
          <h2
            id="confidentiality-version"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 my-4 font-bold lg:text-2xl"
          >
            Que se passe-t-il si CortexxMI modifie la présente politique de
            cofidentialité?
          </h2>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            CortexxMI pourra mettre à jour cette Politique de Confidentialité à
            tout moment pour l’adapter à d’éventuelles nouvelles pratiques et
            offres de service.
          </p>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            Dans ce cas, la date de mise à jour de la Politique de
            Confidentialité sera actualisée et indiquera le jour où les
            modifications ont été effectuées. Si CortexxMI apporte met à jour la
            Politique de Confidentialité, les Utilisateurs seront avertis par
            l’intermédiaire de SUMO®.
          </p>
          <nav id="to-top" className="text-blue-500 lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
          <h2
            id="contact"
            className="lg:w-3/4 w-full leading-relaxed text-gray-600 my-4 font-bold lg:text-2xl"
          >
            Comment l’utilisateur peut-il contacter CortexxMI pour toute demande
            ou question relative à ses données à caractère personel?
          </h2>
          <p className="llg:w-3/4 w-full leading-normal text-gray-500 lg:text-base my-4">
            De manière générale, si l’Utilisateur a des questions ou des
            commentaires concernant cette Politique de Confidentialité,
            l’utilisation que CortexxMI fait de ses données, ou s’il souhaite
            exercer l’un de ses droits, il peut contacter CortexxMI par courrier
            électronique à l’adresse électronique suivant dpo@cortexxmi.com ou
            par courrier postal à l’adresse postale suivante : CortexxMI SAS,
            156 Boulevard Haussmann - 75008 Paris.
          </p>
          <nav id="to-top" className="text-blue-500 lg:text-base">
            <a href="#contents">sommaire</a>
          </nav>
        </article>
      </body>
    </div>
  )
}

export default PrivacyPro
