import React from "react";
import GuideBase from "../../components/GuideBase";
import useLanguage from "../../components/hooks";

const listOfLinks = {
  fr: {
    1: "https://www.ameli.fr/paris/medecin/sante-prevention/pathologies/lombalgies/favoriser-maintien-activite-professionnelle",
    2: "https://www.ameli.fr/paris/assure/sante/themes/lombalgie-aigue/mal-de-dos-emploi",
    3: "https://www.ameli.fr/paris/assure/sante/themes/lombalgie-aigue/traitement-prevention",
  },
  en: {
    1: "https://www.ameli.fr/paris/medecin/sante-prevention/pathologies/lombalgies/favoriser-maintien-activite-professionnelle",
    2: "https://www.ameli.fr/paris/assure/sante/themes/lombalgie-aigue/mal-de-dos-emploi",
    3: "https://www.ameli.fr/paris/assure/sante/themes/lombalgie-aigue/traitement-prevention",
  },
};

const ProfessionalActivity = () => {
  const [t, links] = useLanguage(listOfLinks);

  return (
    <GuideBase>
      <header>
        <p class="lg:w1/2 my-4 w-full text-3xl font-bold leading-relaxed text-gray-600">
          {t("professional_activity.title")}
        </p>
      </header>
      <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
        {t("professional_activity.intro")}
      </p>
      <nav>
        <p
          id="contents"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("common.summary")}
        </p>

        <ul>
          <li>
            <a
              href="#info"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("professional_activity.summary.info")}
            </a>
          </li>
          <li>
            <a
              href="#residual-back-pain"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("professional_activity.summary.residual_back_pain")}
            </a>
          </li>
          <li>
            <a
              href="#good-positions"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("professional_activity.summary.positions")}
            </a>
          </li>
          <li>
            <a
              href="#moving"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("professional_activity.summary.moving")}
            </a>
          </li>
          <li>
            <a
              href="#sources"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("common.sources")}
            </a>
          </li>
        </ul>
      </nav>

      <article>
        <h2
          id="info"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("professional_activity.summary.info")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("professional_activity.info.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("professional_activity.info.content.2")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("professional_activity.info.content.3")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("professional_activity.info.content.4")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("professional_activity.info.content.5")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("professional_activity.info.content.6")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="residual-back-pain"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("professional_activity.summary.residual_back_pain")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("professional_activity.residual_back_pain.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("professional_activity.residual_back_pain.content.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="good-positions"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("professional_activity.summary.positions")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("professional_activity.positions.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("professional_activity.positions.content.2")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("professional_activity.positions.content.3")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("professional_activity.positions.content.4")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="moving"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("professional_activity.summary.moving")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("professional_activity.moving.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("professional_activity.moving.content.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="moving"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("common.sources")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("professional_activity.extra_infos.1")}
          <a
            href={links[1]}
            class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
          >
            {[" ", t("physical_activity.links.1")].join("")}
          </a>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("professional_activity.extra_infos.1")}
          <a
            href={links[1]}
            class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
          >
            {[" ", t("physical_activity.links.1")].join("")}
          </a>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("professional_activity.extra_infos.1")}
          <a
            href={links[1]}
            class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
          >
            {[" ", t("physical_activity.links.1")].join("")}
          </a>
        </p>
        <p class="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">sommaire</a>
        </p>
      </article>
      <script src="{{ asset('css/app.js') }}" />
    </GuideBase>
  );
};

export default ProfessionalActivity;
