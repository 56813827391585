import React from "react";
import GuideBase from "../../components/GuideBase";
import useLanguage from "../../components/hooks";

const listOfLinks = {
  fr: {
    1: "https://www.iasp-pain.org/publications/iasp-news/iasp-announces-revised-definition-of-pain/",
    2: "https://insightsimaging.springeropen.com/articles/10.1186/s13244-022-01246-z#:~:text=Failed%20back%20surgery%20syndrome%20(FBSS,and%20still%20not%20fully%20clarified",
    3: "/sumo/guides/sleep/fr",
    4: "/sumo/guides/wellbeing/fr",
    5: "https://pubmed.ncbi.nlm.nih.gov/31009749",
    6: "https://pubmed.ncbi.nlm.nih.gov/29120927/",
    7: "https://www.stresshumain.ca/le-stress/dejouer-le-stress/etape-de-gestion-du-stress-instantanee",
  },
  en: {
    1: "https://sante.gouv.fr/IMG/pdf/la_douleur_en_questions.pdf",
    2: "https://insightsimaging.springeropen.com/articles/10.1186/s13244-022-01246-z#:~:text=Failed%20back%20surgery%20syndrome%20(FBSS,and%20still%20not%20fully%20clarified",
    3: "/sumo/guides/sleep/en",
    4: "/sumo/guides/wellbeing/en",
    5: "https://pubmed.ncbi.nlm.nih.gov/31009749",
    6: "https://pubmed.ncbi.nlm.nih.gov/29120927/",
    7: "https://www.stresshumain.ca/le-stress/dejouer-le-stress/etape-de-gestion-du-stress-instantanee",
  },
};

const PainManagementSecondPart = () => {
  const [t, links] = useLanguage(listOfLinks);

  return (
    <GuideBase>
      <header>
        <p class="lg:w1/2 my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:text-3xl">
          {t("pain_management_2.title")}
        </p>
      </header>
      <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
        {t("pain_management_2.intro")}
      </p>
      <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
        <img
          src="/img/guides/pain_advices_2.png"
          class="object-cover object-center"
          alt="pain_advices_2"
        />
      </p>
      <nav>
        <p
          id="contents"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("common.summary")}
        </p>

        <ul>
          <li>
            <a
              href="#definition"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4  lg:text-base"
            >
              {t("pain_management_2.summary.definition")}
            </a>
          </li>
          <li>
            <a
              href="#definitions"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4  lg:text-base"
            >
              {t("pain_management_2.summary.definitions")}
            </a>
          </li>
          <li>
            <a
              href="#advices"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4  lg:text-base"
            >
              {t("pain_management_2.summary.advices")}
            </a>
          </li>
        </ul>
      </nav>

      <article>
        <h2
          id="definition-douleur"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("pain_management_2.summary.definition")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.definition.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.definition.content.2")}
        </p>
        <a
          href={links[1]}
          class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
        >
          {[" ", t("pain_management_2.links.1")].join("")}
        </a>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <img
            src="/img/guides/exit_sign-intothedark.jpeg"
            class="object-cover object-center"
            alt="exit_sign-intothedark"
          />
        </p>
        <p class="my-4 w-full text-base font-bold leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.definition.content.3")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="definitions"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("pain_management_2.summary.definitions")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("pain_management_2.definitions.content.1.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.definitions.content.1.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("pain_management_2.definitions.content.2.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.definitions.content.2.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("pain_management_2.definitions.content.3.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.definitions.content.3.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("pain_management_2.definitions.content.4.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <img
            src="/img/guides/pain_advices_2_inflammation.jpg"
            class="object-cover object-center"
            alt="pain_advices_2_inflammation"
          />
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.definitions.content.4.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.definitions.content.4.link.1")}
          <a
            href={links[2]}
            class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
          >
            {[" ", t("pain_management_2.links.2"), " "].join("")}
          </a>
          {t("pain_management_2.definitions.content.4.link.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("pain_management_2.definitions.content.5.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <img
            src="/img/guides/pain_advices_2_sleep.jpg"
            class="object-cover object-center"
            alt="pain_advices_2_sleep"
          />
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.definitions.content.5.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.definitions.content.5.link.1")}
          <a
            href={links[3]}
            class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
          >
            {[" ", t("pain_management_2.links.3"), " "].join("")}
          </a>
          {t("pain_management_2.definitions.content.5.link.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="advices"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("pain_management_2.summary.advices")}
        </h2>

        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("pain_management_2.advices.content.1.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.advices.content.1.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("pain_management_2.advices.content.2.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.advices.content.2.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("pain_management_2.advices.content.3.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.advices.content.3.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("pain_management_2.advices.content.4.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.advices.content.4.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("pain_management_2.advices.content.5.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.advices.content.5.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("pain_management_2.advices.content.6.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.advices.content.6.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("pain_management_2.advices.content.7.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.advices.content.7.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("pain_management_2.advices.content.8.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.advices.content.8.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("pain_management_2.advices.content.9.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.advices.content.9.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("pain_management_2.advices.content.10.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.advices.content.10.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.advices.content.10.link.1")}
          <a
            href={links[4]}
            class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
          >
            {[" ", t("pain_management_2.links.4"), " "].join("")}
          </a>
          {t("pain_management_2.advices.content.10.link.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="sources"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("common.sources")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.extra_infos.1")}
          <a
            href={links[5]}
            class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
          >
            {[" ", t("pain_management_2.links.5"), " "].join("")}
          </a>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.extra_infos.2")}
          <a
            href={links[6]}
            class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
          >
            {[" ", t("pain_management_2.links.6"), " "].join("")}
          </a>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("pain_management_2.extra_infos.3")}
          <a
            href={links[7]}
            class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
          >
            {[" ", t("pain_management_2.links.7"), " "].join("")}
          </a>
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
      </article>
    </GuideBase>
  );
};

export default PainManagementSecondPart;
