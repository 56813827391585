import React from "react";

const FunctionsCard = (props) => {
  const { focused, setFocused } = props;

  return (
    <div class={`flex bg-gradient-to-tl from-cortexx-600 ${props.color}`}>
      <div class="mt-2 flex flex-col bg-cortexx-50 md:ml-2 md:mt-0 md:pl-8">
        <h1 class="pt-4 text-2xl font-extrabold tracking-tight text-cortexx-900 md:pt-0 md:text-4xl">
          <span class="block xl:inline">{props.title}</span>
        </h1>
        {props.children}
      </div>
    </div>
  );
};

export default FunctionsCard;
