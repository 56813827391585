import React from "react";
import { useTranslation } from "react-i18next";

const PatientBenefit = () => {
  const { t } = useTranslation();

  return (
    <section
      id="patient_benefits"
      className="body-font mt-20 bg-cortexx-900 text-gray-700"
    >
      <div className="container mx-auto flex flex-col px-5 pt-10 md:pt-20">
        <div className="mb-10 flex  w-full flex-col">
          <div className="mb-10">
            <h1 class="text-3xl font-extrabold tracking-tight text-white md:text-5xl">
              <span class="inline">{t("patient_benefits.title.line_1")}</span>
              <span class="mr-3 inline"></span>
              <span class="inline text-cortexx-400">
                {t("patient_benefits.title.line_2")}
              </span>
            </h1>
          </div>
          <div className="mx-auto flex flex-col md:flex-row">
            <div className="h-auto rounded-xl bg-cortexx-100 p-8 leading-relaxed transition-all duration-500 hover:bg-slate-100 hover:bg-gradient-to-tl hover:from-slate-400 md:mr-20 md:w-96 md:hover:mr-32">
              <h2 className="mb-8 text-2xl font-bold text-cortexx-400">
                {t("patient_benefits.rpm.title")}
              </h2>
              <p className="mb-8 text-gray-500">
                {t("patient_benefits.rpm.text_1")}
              </p>
              <p className="mb-8 font-bold italic text-cortexx-700">
                {t("patient_benefits.rpm.highlight_1")}
              </p>
              <p className="mb-8 text-gray-500">
                {t("patient_benefits.rpm.text_2")}
              </p>
              <p className="font-bold italic text-cortexx-700">
                {t("patient_benefits.rpm.highlight_2")}
              </p>
            </div>
            <div className="mt-10 h-auto rounded-xl bg-cortexx-100 p-8 leading-relaxed transition-all duration-500 hover:bg-slate-100  hover:bg-gradient-to-tl hover:from-slate-400  md:mr-20 md:mt-0 md:w-96 md:hover:ml-12 md:hover:mr-32">
              <h2 className="mb-8 text-2xl font-bold text-cortexx-700">
                {t("patient_benefits.dtx.title")}
              </h2>
              <p className="mb-8 text-gray-500">
                {t("patient_benefits.dtx.text_1")}
              </p>
              <p className="mb-8 text-gray-500">
                {t("patient_benefits.dtx.text_2")}
              </p>
              <p className="align-bottom font-bold italic text-cortexx-700">
                {t("patient_benefits.dtx.highlight")}
              </p>
            </div>
            <div className="mt-10 h-auto rounded-xl bg-cortexx-100 p-8 leading-relaxed transition-all duration-500 hover:bg-slate-100 hover:bg-gradient-to-tl hover:from-slate-400 md:mt-0 md:w-96 md:hover:ml-12">
              <h2 className="mb-8 text-2xl font-bold text-cortexx-200">
                {t("patient_benefits.digital_twins.title")}
              </h2>
              <p className="mb-8 text-gray-500">
                {t("patient_benefits.digital_twins.text_1")}
              </p>
              <p className="mb-8 text-gray-500">
                {t("patient_benefits.digital_twins.text_2")}
              </p>
              <p className="font-bold italic text-cortexx-700">
                {t("patient_benefits.digital_twins.highlight")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PatientBenefit;
