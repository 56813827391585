import React, { useEffect } from "react";

const GuideBase = (props) => {
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage("end of doc");
      }
    }
  };

  return (
    <body class="relative w-full overflow-hidden p-5 antialiased lg:p-10">
      {props.children}
    </body>
  );
};

export default GuideBase;
