import React from "react";
import GuideBase from "../../components/GuideBase";
import useLanguage from "../../components/hooks";

const listOfLinks = {
  fr: {
    1: "https://www.mangerbouger.fr/manger-mieux/a-tout-age-et-a-chaque-etape-de-la-vie/les-recommandations-alimentaires-pour-les-adultes",
    2: "https://sante.gouv.fr/IMG/pdf/pnns4_2019-2023.pdf",
    3: "https://www.has-sante.fr/upload/docs/application/pdf/2022-06/reco369_recommandations_obesite_2e_3e_niveaux_preparation_mel_v4_2.pdf",
    4: "https://www.ameli.fr/paris/assure/sante/themes/anemie-carence-fer/soins-prescrits",
    5: "https://www.ameli.fr/paris/assure/sante/themes/syndrome-intestin-irritable/consultation-medicale-traitement",
    6: "https://solidarites-sante.gouv.fr/IMG/pdf/guide_alimentairetous.pdf",
    7: "https://www.travers-e.com/single-post/2018/08/30/Lindice-Pral",
  },
  en: {
    1: "https://www.mangerbouger.fr/manger-mieux/a-tout-age-et-a-chaque-etape-de-la-vie/les-recommandations-alimentaires-pour-les-adultes",
    2: "https://sante.gouv.fr/IMG/pdf/pnns4_2019-2023.pdf",
    3: "https://www.has-sante.fr/upload/docs/application/pdf/2022-06/reco369_recommandations_obesite_2e_3e_niveaux_preparation_mel_v4_2.pdf",
    4: "https://www.ameli.fr/paris/assure/sante/themes/anemie-carence-fer/soins-prescrits",
    5: "https://www.ameli.fr/paris/assure/sante/themes/syndrome-intestin-irritable/consultation-medicale-traitement",
    6: "https://solidarites-sante.gouv.fr/IMG/pdf/guide_alimentairetous.pdf",
    7: "https://www.travers-e.com/single-post/2018/08/30/Lindice-Pral",
  },
};

const Alimentation = () => {
  const [t, links] = useLanguage(listOfLinks);

  return (
    <GuideBase>
      <header>
        <p className="lg:w1/2 my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:text-3xl">
          {t("alimentation.title")}
        </p>
      </header>

      <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
        {t("alimentation.intro")}
      </p>
      <nav>
        <p
          id="contents"
          className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("common.summary")}
        </p>

        <ul>
          <li>
            <a
              href="#intro"
              className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-sm"
            >
              {t("common.introduction")}
            </a>
          </li>
          <li>
            <a
              href="#fodmap"
              className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-sm"
            >
              {t("alimentation.summary.fodmap")}
            </a>
          </li>
          <li>
            <a
              href="#pral"
              className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-sm"
            >
              {t("alimentation.summary.pral")}
            </a>
          </li>
          <li>
            <a
              href="#rules_hygiene"
              className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-sm"
            >
              {t("alimentation.summary.dietetics")}
            </a>
          </li>
          <li>
            <a
              href="#omegas"
              className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-sm"
            >
              {t("alimentation.summary.omegas")}
            </a>
          </li>
          <li>
            <a
              href="#polyphenols"
              className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-sm"
            >
              {t("alimentation.summary.polyphenols")}
            </a>
          </li>
          <li>
            <a
              href="#sources"
              className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-sm"
            >
              {t("common.sources")}
            </a>
          </li>
        </ul>
      </nav>

      <article>
        <h2
          id="intro"
          className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("common.introduction")}
        </h2>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.introduction.content.1")}
        </p>
        <p className="my-4 w-full text-base font-bold leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.introduction.content.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href={links[1]}>{t("alimentation.links.1")}</a>
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("alimentation.introduction.nutriscore.title")}</b>
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.introduction.nutriscore.content.1")}
        </p>

        <ul>
          <li className="mx-8 w-full text-base leading-normal text-gray-500 sm:list-disc lg:w-3/4 lg:text-base">
            {t("alimentation.introduction.nutriscore.content.list_1.1")}
          </li>
          <li className="mx-8 w-full text-base leading-normal text-gray-500 sm:list-disc lg:w-3/4 lg:text-base">
            {t("alimentation.introduction.nutriscore.content.list_1.2")}
          </li>
        </ul>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.introduction.nutriscore.content.2")}
        </p>
        <ul>
          <li className="mx-8 w-full text-base leading-normal text-gray-500 sm:list-disc lg:w-3/4 lg:text-base">
            {t("alimentation.introduction.nutriscore.content.list_2.1")}
          </li>
          <li className="mx-8 w-full text-base leading-normal text-gray-500 sm:list-disc lg:w-3/4 lg:text-base">
            {t("alimentation.introduction.nutriscore.content.list_2.2")}
          </li>
        </ul>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.introduction.nutriscore.content.3")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("alimentation.introduction.fruits.title")}</b>
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.introduction.fruits.content.1")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.introduction.fruits.content.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("alimentation.introduction.pulses.title")}</b>
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.introduction.pulses.content.1")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.introduction.pulses.content.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("alimentation.introduction.whole_starches.title")}</b>
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.introduction.whole_starches.content.1")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.introduction.whole_starches.content.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("alimentation.introduction.fish.title")}</b>
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.introduction.fish.content.1")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("alimentation.introduction.oil.title")}</b>
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.introduction.oil.content.1")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.introduction.oil.content.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("alimentation.introduction.milk.title")}</b>
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.introduction.milk.content.1")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.introduction.milk.content.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="fodmap"
          className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("alimentation.summary.fodmap")}
        </h2>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.fodmap.content.1")}
        </p>
        <ul>
          <li className="mx-8 w-full text-base leading-normal text-gray-500 sm:list-disc lg:w-3/4 lg:text-base">
            {t("alimentation.fodmap.content.list.1")}
          </li>
          <li className="mx-8 w-full text-base leading-normal text-gray-500 sm:list-disc lg:w-3/4 lg:text-base">
            {t("alimentation.fodmap.content.list.2")}
          </li>
          <li className="mx-8 w-full text-base leading-normal text-gray-500 sm:list-disc lg:w-3/4 lg:text-base">
            {t("alimentation.fodmap.content.list.3")}
          </li>
          <li className="mx-8 w-full text-base leading-normal text-gray-500 sm:list-disc lg:w-3/4 lg:text-base">
            {t("alimentation.fodmap.content.list.4")}
          </li>
        </ul>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.fodmap.content.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="pral"
          className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("alimentation.summary.pral")}
        </h2>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <img
            src="/img/guides/indicepral.jpg"
            className="object-cover object-center"
            alt="guide_pral"
          />
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.pral.content.1")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.pral.content.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.pral.content.3")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.pral.content.4")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="rules_hygiene"
          className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("alimentation.summary.dietetics")}
        </h2>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.dietetics.content.1")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.dietetics.content.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="omegas"
          className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("alimentation.summary.omegas")}
        </h2>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.omegas.content.1")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.omegas.content.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="polyphenols"
          className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("alimentation.summary.polyphenols")}
        </h2>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.polyphenols.content.1")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.polyphenols.content.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.polyphenols.content.3")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="sources"
          className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("common.sources")}
        </h2>
        <p className="my-4 w-full text-base font-bold leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.extra_infos.1")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href={links[1]}>{t("alimentation.links.1")}</a>
        </p>
        <p className="my-4 w-full text-base font-bold leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.extra_infos.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href={links[2]}>{t("alimentation.links.2")}</a>
        </p>
        <p className="my-4 w-full text-base font-bold leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.extra_infos.3")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href={links[3]}>{t("alimentation.links.3")}</a>
        </p>
        <p className="my-4 w-full text-base font-bold leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.extra_infos.4")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href={links[4]}>{t("alimentation.links.4")}</a>
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href={links[5]}>{t("alimentation.links.5")}</a>
        </p>
        <p className="my-4 w-full text-base font-bold leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.extra_infos.5")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href={links[6]}>{t("alimentation.links.6")}</a>
        </p>
        <p className="my-4 w-full text-base font-bold leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.extra_infos.sources.title")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.extra_infos.sources.1")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.extra_infos.sources.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("alimentation.extra_infos.sources.3")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href={links[7]}>{t("alimentation.links.7")}</a>
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
      </article>
    </GuideBase>
  );
};

export default Alimentation;
