import React from "react";
import useScreenSize from "../utils/useScreenSize";

const SolutionCard = (props) => {
  const { id, name, description, icon, sectionLink } = props.card;
  const { focused, setFocused } = props;
  const screenSize = useScreenSize();

  return (
    <a
      class={`flex flex-col items-center justify-center transition-all duration-1000 md:flex-row ${
        focused === "none"
          ? "opacity-100"
          : focused === id
          ? "opacity-100"
          : "opacity-50"
      }`}
      onMouseOver={() => screenSize.width > 620 && setFocused(id)}
      onMouseOut={() => screenSize.width > 620 && setFocused("none")}
      onClick={(e) => {
        if (screenSize.width < 620) setFocused(id);
      }}
      href={sectionLink}
    >
      <div class="mb-2 flex h-20 w-20 items-center justify-center rounded-xl bg-cortexx-1000 bg-opacity-50">
        {icon}
      </div>
      <div class="mx-1 flex w-32 items-center justify-center text-center md:ml-4 md:justify-start md:text-left">
        <p class="text-xs font-bold text-cortexx-50">{name}</p>
      </div>
      <div class="hidden text-center md:ml-4 md:flex md:w-64 md:text-left">
        <p
          class={`font-base text-s text-cortexx-50 transition-all duration-1000 md:text-xs ${
            focused === id ? "opacity-100" : "opacity-0"
          }`}
        >
          {description}
        </p>
      </div>
    </a>
  );
};

export default SolutionCard;
