import React from "react";
import GuideBase from "../../components/GuideBase";
import useLanguage from "../../components/hooks";

const listOfLinks = {
  fr: {
    1: "https://www.sciencedirect.com/science/article/abs/pii/S1169833021002076",
    2: "https://www.ulaval.ca/mon-equilibre-ul/mes-habitudes-de-vie/coherence-cardiaque#:~:text=Comment%20la%20coh%C3%A9rence%20cardiaque%20se,%2C%20ample%2C%20r%C3%A9guli%C3%A8re%20et%20abdominale.",
    3: "https://ggia.berkeley.edu/practice/mindful_breathing",
  },
  en: {
    1: "https://www.sciencedirect.com/science/article/abs/pii/S1169833021002076",
    2: "https://www.ulaval.ca/mon-equilibre-ul/mes-habitudes-de-vie/coherence-cardiaque#:~:text=Comment%20la%20coh%C3%A9rence%20cardiaque%20se,%2C%20ample%2C%20r%C3%A9guli%C3%A8re%20et%20abdominale.",
    3: "https://ggia.berkeley.edu/practice/mindful_breathing",
  },
};

const WellBeing = () => {
  const [t, links] = useLanguage(listOfLinks);

  return (
    <GuideBase>
      <header>
        <p class="lg:w1/2 my-4 w-full text-3xl font-bold leading-relaxed text-gray-600">
          {t("wellbeing.title")}
        </p>
      </header>
      <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
        {t("wellbeing.intro")}
      </p>
      <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
        <img
          src="/img/guides/wellbeing_meditation.png"
          class="object-cover object-center"
          alt="wellbeing_meditation"
        />
      </p>
      <nav>
        <p
          id="contents"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("common.summary")}
        </p>

        <ul>
          <li>
            <a
              href="#vagus-nerve"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base lg:text-sm"
            >
              {t("wellbeing.summary.vagus_nerve")}
            </a>
          </li>
          <li>
            <a
              href="#relaxation"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base lg:text-sm"
            >
              {t("wellbeing.summary.relaxation")}
            </a>
          </li>
          <li>
            <a
              href="#sources"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("common.sources")}
            </a>
          </li>
        </ul>
      </nav>

      <article>
        <h2
          id="vagus-nerve"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("wellbeing.summary.vagus_nerve")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("wellbeing.vagus_nerve.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("wellbeing.vagus_nerve.content.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="relaxation"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("wellbeing.summary.relaxation")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("wellbeing.relaxation.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("wellbeing.relaxation.content.coherence.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("wellbeing.relaxation.content.coherence.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("wellbeing.relaxation.content.coherence.content.2")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("wellbeing.relaxation.content.breathing.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("wellbeing.relaxation.content.breathing.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("wellbeing.relaxation.content.connexion.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("wellbeing.relaxation.content.connexion.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("wellbeing.relaxation.content.dive.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("wellbeing.relaxation.content.dive.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("wellbeing.relaxation.content.sing.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("wellbeing.relaxation.content.sing.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("wellbeing.relaxation.content.om.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("wellbeing.relaxation.content.om.content")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="sources"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("common.sources")}
        </h2>
        {[...Array(3)].map((_, i) => (
          <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t(`wellbeing.extra_infos.${i + 1}`)}
            <a
              href={links[i + 1]}
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {[" ", t(`wellbeing.links.${i + 1}`), "."].join("")}
            </a>
          </p>
        ))}
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
      </article>
    </GuideBase>
  );
};

export default WellBeing;
