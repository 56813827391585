import React from "react";
import GuideBase from "../../components/GuideBase";
import useLanguage from "../../components/hooks";

const listOfLinks = {
  fr: {
    1: "https://www.ameli.fr/paris/medecin/exercice-liberal/memos/depistage-et-prevention/troubles-sommeil",
    2: "https://www.ameli.fr/sites/default/files/Documents/4892/document/apprendre-dormir-conseils_assurance-maladie.pdf",
    3: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1418327",
    4: "https://pubmed.ncbi.nlm.nih.gov/16496209",
    5: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4046588",
    6: "https://pubmed.ncbi.nlm.nih.gov/29120927/",
    7: "/sumo/guides/feeding/fr",
    8: "https://www.sciencedirect.com/science/article/abs/pii/S1529943021001583",
  },
  en: {
    1: "https://www.ameli.fr/paris/medecin/exercice-liberal/memos/depistage-et-prevention/troubles-sommeil",
    2: "https://www.ameli.fr/sites/default/files/Documents/4892/document/apprendre-dormir-conseils_assurance-maladie.pdf",
    3: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1418327",
    4: "https://pubmed.ncbi.nlm.nih.gov/16496209",
    5: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4046588",
    6: "https://pubmed.ncbi.nlm.nih.gov/29120927/",
    7: "/sumo/guides/feeding/en",
    8: "https://www.sciencedirect.com/science/article/abs/pii/S1529943021001583",
  },
};

const Sleep = () => {
  const [t, links] = useLanguage(listOfLinks);

  return (
    <GuideBase>
      <header>
        <p class="lg:w1/2 my-4 w-full text-3xl font-bold leading-relaxed text-gray-600">
          {t("sleep.title")}
        </p>
      </header>
      <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
        {t("sleep.intro")}
      </p>
      <nav>
        <p
          id="contents"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("common.summary")}
        </p>

        <ul>
          <li>
            <a
              href="#definition"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("sleep.summary.definition")}
            </a>
          </li>
          <li>
            <a
              href="#evolution"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("sleep.summary.evolution")}
            </a>
          </li>
          <li>
            <a
              href="#conservative-measures"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("sleep.summary.conservative_measures")}
            </a>
          </li>
          <li>
            <a
              href="#advices"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("sleep.summary.advices")}
            </a>
          </li>
          <li>
            <a
              href="#exercices"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("sleep.summary.exercices.title")}
            </a>
          </li>
          <ul>
            <li>
              <a
                href="#exercice-1"
                class="mx-4 w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("sleep.summary.exercices.list.1")}
              </a>
            </li>
            <li>
              <a
                href="#exercice-2"
                class="mx-4 w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("sleep.summary.exercices.list.2")}
              </a>
            </li>
            <li>
              <a
                href="#exercice-3"
                class="mx-4 w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("sleep.summary.exercices.list.3")}
              </a>
            </li>
            <li>
              <a
                href="#exercice-4"
                class="mx-4 w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("sleep.summary.exercices.list.4")}
              </a>
            </li>
            <li>
              <a
                href="#exercice-5"
                class="mx-4 w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("sleep.summary.exercices.list.5")}
              </a>
            </li>
            <li>
              <a
                href="#exercice-6"
                class="mx-4 w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("sleep.summary.exercices.list.6")}
              </a>
            </li>
            <li>
              <a
                href="#exercice-7"
                class="mx-4 w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("sleep.summary.exercices.list.7")}
              </a>
            </li>
            <li>
              <a
                href="#exercice-8"
                class="mx-4 w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("sleep.summary.exercices.list.8")}
              </a>
            </li>
            <li>
              <a
                href="#exercice-9"
                class="mx-4 w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("sleep.summary.exercices.list.9")}
              </a>
            </li>
            <li>
              <a
                href="#exercice-10"
                class="mx-4 w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("sleep.summary.exercices.list.10")}
              </a>
            </li>
          </ul>
        </ul>
      </nav>

      <article>
        <h2
          id="definition"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("sleep.summary.definition")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.definition.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.definition.content.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="evolution"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("sleep.summary.evolution")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("sleep.evolution.content.1.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.evolution.content.1.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("sleep.evolution.content.2.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.evolution.content.2.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("sleep.evolution.content.3.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.evolution.content.3.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("sleep.evolution.content.4.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.evolution.content.4.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("sleep.evolution.content.5.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.evolution.content.5.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.evolution.content.5.content.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="conservative-measures"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("sleep.summary.conservative_measures")}
        </h2>
        <ul class="mx-8 w-5/6 list-decimal text-base font-normal leading-normal text-gray-500 lg:w-3/4">
          <li>
            <p class="my-4">{t("sleep.conservative_measures.list.1")}</p>
          </li>
          <li>
            <p class="my-4">{t("sleep.conservative_measures.list.2")}</p>
          </li>
          <li>
            <p class="my-4">{t("sleep.conservative_measures.list.3")}</p>
          </li>
          <li>
            <p class="my-4">{t("sleep.conservative_measures.list.4")}</p>
          </li>
          <li>
            <p class="my-4">{t("sleep.conservative_measures.list.5")}</p>
          </li>
          <li>
            <p class="my-4">{t("sleep.conservative_measures.list.6")}</p>
          </li>
        </ul>
        <p
          id="advices"
          class="my-4 w-full text-2xl leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          <b>{t("sleep.summary.advices")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          <b>{t("sleep.advices.sleep_ecology.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.advices.sleep_ecology.content")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.advices.sleep_ecology.lists.1.title")}
        </p>
        <ul class="mx-8 w-5/6 list-disc text-base font-normal leading-normal text-gray-500 lg:w-3/4">
          <li>
            <p class="my-4">
              {t("sleep.advices.sleep_ecology.lists.1.content.1")}
            </p>
          </li>
          <li>
            <p class="my-4">
              {t("sleep.advices.sleep_ecology.lists.1.content.2")}
            </p>
          </li>
        </ul>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.advices.sleep_ecology.lists.2.title")}
        </p>
        <ul class="mx-8 w-5/6 list-disc text-base font-normal leading-normal text-gray-500 lg:w-3/4">
          <li>
            <p class="my-4">
              {t("sleep.advices.sleep_ecology.lists.2.content.1")}
            </p>
          </li>
          <li>
            <p class="my-4">
              {t("sleep.advices.sleep_ecology.lists.2.content.2")}
            </p>
          </li>
          <li>
            <p class="my-4">
              {t("sleep.advices.sleep_ecology.lists.2.content.3")}
            </p>
          </li>
          <li>
            <p class="my-4">
              {t("sleep.advices.sleep_ecology.lists.2.content.4")}
            </p>
          </li>
          <li>
            <p class="my-4">
              {t("sleep.advices.sleep_ecology.lists.2.content.5")}
            </p>
          </li>
        </ul>
        <p
          id="dietetic"
          class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base"
        >
          <b>{t("sleep.advices.dietetics.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.advices.dietetics.content")}
        </p>
        <ul class="mx-8 w-5/6 list-disc text-base font-normal leading-normal text-gray-500 lg:w-3/4">
          <li>
            <p class="my-4">{t("sleep.advices.dietetics.list.1")}</p>
          </li>
          <li>
            <p class="my-4">{t("sleep.advices.dietetics.list.2")}</p>
          </li>
          <li>
            <p class="my-4">{t("sleep.advices.dietetics.list.3")}</p>
          </li>
          <li>
            <p class="my-4">{t("sleep.advices.dietetics.list.4")}</p>
          </li>
        </ul>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.advices.dietetics.link.1")}
          <a
            href={links[7]}
            class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
          >
            {[" ", t("sleep.links.7"), " "].join("")}
          </a>
          {t("sleep.advices.dietetics.link.2")}
        </p>
        <p
          id="dietetic"
          class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base"
        >
          <b>{t("sleep.advices.calm.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.advices.calm.content")}
        </p>
        <ul class="mx-8 w-5/6 list-disc text-base font-normal leading-normal text-gray-500 lg:w-3/4">
          <li>
            <p class="my-4">{t("sleep.advices.calm.list.1")}</p>
          </li>
          <li>
            <p class="my-4">{t("sleep.advices.calm.list.2")}</p>
          </li>
          <li>
            <p class="my-4">{t("sleep.advices.calm.list.3")}</p>
          </li>
        </ul>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <p
          id="exercices"
          class="my-4 w-full text-2xl leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          <b>{t("sleep.summary.exercices.title")}</b>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.exercices.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.exercices.content.2")}
        </p>
        <h3
          id="exercice-1"
          class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl"
        >
          {t("sleep.summary.exercices.list.1")}
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.exercices.list.1.content.1")}
        </p>
        <ul class="mx-8 w-5/6 list-disc text-base font-normal leading-normal text-gray-500 lg:w-3/4">
          {[...Array(8)].map((_, i) => (
            <li>
              <p class="my-4">
                {t(`sleep.exercices.list.1.content.list.${i + 1}`)}
              </p>
            </li>
          ))}
        </ul>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h3
          id="exercice-2"
          class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl"
        >
          {t("sleep.summary.exercices.list.2")}
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.exercices.list.2.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.exercices.list.2.content.2")}
        </p>
        <ul class="mx-8 w-5/6 list-disc text-base font-normal leading-normal text-gray-500 lg:w-3/4">
          {[...Array(7)].map((_, i) => (
            <li>
              <p class="my-4">
                {t(`sleep.exercices.list.2.content.list.${i + 1}`)}
              </p>
            </li>
          ))}
        </ul>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h3
          id="exercice-3"
          class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl"
        >
          {t("sleep.summary.exercices.list.3")}
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.exercices.list.3.content.1")}
        </p>
        <ul class="mx-8 w-5/6 list-disc text-base font-normal leading-normal text-gray-500 lg:w-3/4">
          {[...Array(7)].map((_, i) => (
            <li>
              <p class="my-4">
                {t(`sleep.exercices.list.3.content.list.${i + 1}`)}
              </p>
            </li>
          ))}
        </ul>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h3
          id="exercice-4"
          class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl"
        >
          {t("sleep.summary.exercices.list.4")}
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.exercices.list.4.content.1")}
        </p>
        <ul class="mx-8 w-5/6 list-disc text-base font-normal leading-normal text-gray-500 lg:w-3/4">
          {[...Array(6)].map((_, i) => (
            <li>
              <p class="my-4">
                {t(`sleep.exercices.list.4.content.list.${i + 1}`)}
              </p>
            </li>
          ))}
        </ul>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h3
          id="exercice-5"
          class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl"
        >
          {t("sleep.summary.exercices.list.5")}
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.exercices.list.5.content.1")}
        </p>
        <ul class="mx-8 w-5/6 list-disc text-base font-normal leading-normal text-gray-500 lg:w-3/4">
          {[...Array(6)].map((_, i) => (
            <li>
              <p class="my-4">
                {t(`sleep.exercices.list.5.content.list.${i + 1}`)}
              </p>
            </li>
          ))}
        </ul>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h3
          id="exercice-6"
          class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl"
        >
          {t("sleep.summary.exercices.list.6")}
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.exercices.list.6.content.1")}
        </p>
        <ul class="mx-8 w-5/6 list-disc text-base font-normal leading-normal text-gray-500 lg:w-3/4">
          {[...Array(6)].map((_, i) => (
            <li>
              <p class="my-4">
                {t(`sleep.exercices.list.6.content.list.${i + 1}`)}
              </p>
            </li>
          ))}
        </ul>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h3
          id="exercice-7"
          class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl"
        >
          {t("sleep.summary.exercices.list.7")}
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.exercices.list.7.content.1")}
        </p>
        <ul class="mx-8 w-5/6 list-disc text-base font-normal leading-normal text-gray-500 lg:w-3/4">
          {[...Array(7)].map((_, i) => (
            <li>
              <p class="my-4">
                {t(`sleep.exercices.list.7.content.list.${i + 1}`)}
              </p>
            </li>
          ))}
        </ul>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h3
          id="exercice-8"
          class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl"
        >
          {t("sleep.summary.exercices.list.8")}
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.exercices.list.8.content.1")}
        </p>
        <ul class="mx-8 w-5/6 list-disc text-base font-normal leading-normal text-gray-500 lg:w-3/4">
          {[...Array(6)].map((_, i) => (
            <li>
              <p class="my-4">
                {t(`sleep.exercices.list.8.content.list.${i + 1}`)}
              </p>
            </li>
          ))}
        </ul>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h3
          id="exercice-9"
          class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl"
        >
          {t("sleep.summary.exercices.list.9")}
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.exercices.list.9.content.1")}
        </p>
        <ul class="mx-8 w-5/6 list-disc text-base font-normal leading-normal text-gray-500 lg:w-3/4">
          {[...Array(8)].map((_, i) => (
            <li>
              <p class="my-4">
                {t(`sleep.exercices.list.9.content.list.${i + 1}`)}
              </p>
            </li>
          ))}
        </ul>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h3
          id="exercice-10"
          class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl"
        >
          {t("sleep.summary.exercices.list.10")}
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.exercices.list.10.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.exercices.list.10.content.2")}
        </p>
        <ul class="mx-8 w-5/6 list-disc text-base font-normal leading-normal text-gray-500 lg:w-3/4">
          {[...Array(9)].map((_, i) => (
            <li>
              <p class="my-4">
                {t(`sleep.exercices.list.10.content.list.${i + 1}`)}
              </p>
            </li>
          ))}
        </ul>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="sources"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("common.sources")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.extra_infos.1")}
          <a
            href={links[1]}
            class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
          >
            {[" ", t("sleep.links.1")].join("")}
          </a>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.extra_infos.2")}
          <a
            href={links[2]}
            class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
          >
            {[" ", t("sleep.links.2")].join("")}
          </a>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.extra_infos.3")}
          <ul class="mx-8 w-5/6 list-disc text-base font-normal leading-normal text-gray-500 lg:w-3/4">
            <li>
              <a
                href={links[3]}
                class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {[" ", t("sleep.links.3")].join("")}
              </a>
            </li>
            <li>
              <a
                href={links[4]}
                class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {[" ", t("sleep.links.4")].join("")}
              </a>
            </li>
          </ul>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.extra_infos.4")}
          <a
            href={links[5]}
            class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
          >
            {[" ", t("sleep.links.5")].join("")}
          </a>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.extra_infos.5")}
          <a
            href={links[6]}
            class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
          >
            {[" ", t("sleep.links.6")].join("")}
          </a>
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("sleep.extra_infos.6")}
          <a
            href={links[7]}
            class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
          >
            {[" ", t("sleep.links.7")].join("")}
          </a>
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
      </article>
    </GuideBase>
  );
};

export default Sleep;
