import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const Terms = () => {
  const { t, i18n } = useTranslation(["legal"]);
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang || "en");
  }, [lang, i18n]);

  return (
    <div className="w-full p-5 lg:p-10">
      <body className="relative w-full antialiased">
        <header>
          <p className="lg:w1/2 my-4 w-full text-3xl font-bold leading-relaxed text-gray-600 lg:text-4xl">
            {t("terms.title")}
          </p>
        </header>

        <nav>
          <p
            id="contents"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("common.summary")}
          </p>
          <ul>
            <li>
              <a
                href="#start"
                className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("terms.summary.start")}
              </a>
            </li>
            <li>
              <a
                href="#object-of-use"
                className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("terms.summary.object")}
              </a>
            </li>
            <li>
              <a
                href="#access-conditions"
                className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("terms.summary.conditions")}
              </a>
            </li>
            <li>
              <a
                href="#user-account"
                className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("terms.summary.account")}
              </a>
            </li>
            <li>
              <a
                href="#services"
                className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("terms.summary.services")}
              </a>
            </li>
            <li>
              <a
                href="#obligations"
                className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("terms.summary.obligations")}
              </a>
            </li>
            <li>
              <a
                href="#liability"
                className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("terms.summary.liability")}
              </a>
            </li>
            <li>
              <a
                href="#restrictions"
                className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("terms.summary.restrictions")}
              </a>
            </li>
            <li>
              <a
                href="#intellectual-property"
                className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("terms.summary.property")}
              </a>
            </li>
            <li>
              <a
                href="#respect-tcus"
                className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("terms.summary.respect")}
              </a>
            </li>
            <li>
              <a
                href="#major-force"
                className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("terms.summary.major")}
              </a>
            </li>
            <li>
              <a
                href="#misc"
                className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("terms.summary.misc")}
              </a>
            </li>
            <li>
              <a
                href="#tcus-time"
                className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("terms.summary.duration")}
              </a>
            </li>
            <li>
              <a
                href="#data-privacy"
                className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("terms.summary.privacy")}
              </a>
            </li>
            <li>
              <a
                href="#hosting"
                className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("terms.summary.hosting")}
              </a>
            </li>
            <li>
              <a
                href="#general-dispositions"
                className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {t("terms.summary.dispositions")}
              </a>
            </li>
          </ul>
        </nav>

        <article>
          <p
            id="start"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("terms.summary.start")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.start.1")}&nbsp;
            <a
              href="mailto:dpo@cortexxmi.com"
              className="w-full text-base font-bold leading-snug text-blue-500 underline lg:w-3/4 lg:text-base"
            >
              dpo@cortexxmi.com
            </a>
            &nbsp;{t("terms.start.2")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.start.3")}
          </p>
          <nav id="to-top" className="text-base text-blue-500 lg:text-base">
            <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
          </nav>
          <p
            id="object-of-use"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("terms.summary.object")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.object.1")}
          </p>

          <nav id="to-top" className="text-base text-blue-500 lg:text-base">
            <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
          </nav>
          <p
            id="access-conditions"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("terms.summary.conditions")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.conditions.1")}&nbsp;
            <a
              href="https://www.cortexxmi.com"
              className="w-full text-base font-bold leading-snug text-blue-500 underline lg:w-3/4 lg:text-base"
            >
              https://www.cortexxmi.com.
            </a>
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.conditions.2")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.conditions.3")}
          </p>

          <nav id="to-top" className="text-base text-blue-500 lg:text-base">
            <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
          </nav>
          <p
            id="user-account"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("terms.summary.account")}
          </p>
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl">
            {t("terms.account.creation.title")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.account.creation.1")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.account.creation.2")}
          </p>
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl">
            {t("terms.account.modification.title")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.account.modification.1")}
          </p>
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl">
            {t("terms.account.duration.title")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.account.duration.1")}
          </p>
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl">
            {t("terms.account.termination.title")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.account.termination.1")}
          </p>
          <a
            href="https://cortexxmi.com/sumo/user-privacy"
            className="w-full text-base font-bold leading-snug text-blue-500 underline lg:w-3/4 lg:text-base"
          >
            https://cortexxmi.com/sumo/user-privacy
          </a>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.account.termination.2")}
          </p>
          <a
            href="https://cortexxmi.com/sumo/user-privacy"
            className="w-full text-base font-bold leading-snug text-blue-500 underline lg:w-3/4 lg:text-base"
          >
            https://cortexxmi.com/sumo/user-privacy
          </a>
          <p
            id="services"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("terms.summary.services")}
          </p>
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl">
            {t("terms.services.follow.title")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.services.follow.1")}
          </p>
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl">
            {t("terms.services.diagnostic.title")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.services.diagnostic.1")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.services.diagnostic.2")}
          </p>
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl">
            {t("terms.services.others.title")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.services.others.1")}
          </p>

          <nav id="to-top" className="text-base text-blue-500 lg:text-base">
            <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
          </nav>
          <p
            id="obligations"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("terms.summary.obligations")}
          </p>
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl">
            {t("terms.obligations.user.title")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.obligations.user.1")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.obligations.user.2")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.obligations.user.3")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.obligations.user.4")}
          </p>
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl">
            {t("terms.obligations.cortexx.title")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.obligations.cortexx.1")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.obligations.cortexx.2")}
          </p>

          <nav id="to-top" className="text-base text-blue-500 lg:text-base">
            <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
          </nav>
          <p
            id="liability"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("terms.summary.liability")}
          </p>
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl">
            {t("terms.liability.user.title")}
          </p>
          {[...Array(2)].map((_, i) => (
            <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
              {t(`terms.liability.user.${i + 1}`)}
            </p>
          ))}
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl">
            {t("terms.liability.cortexx.title")}
          </p>
          {[...Array(7)].map((_, i) => (
            <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
              {t(`terms.liability.cortexx.${i + 1}`)}
            </p>
          ))}
          <nav id="to-top" className="text-base text-blue-500 lg:text-base">
            <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
          </nav>
          <p
            id="restrictions"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("terms.summary.restrictions")}
          </p>
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl">
            {t("terms.restrictions.accept.title")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.restrictions.accept.1")}
          </p>
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl">
            {t("terms.restrictions.forbidden.title")}
          </p>
          <ul class="mx-8 w-5/6 list-disc text-base leading-normal text-gray-500 lg:w-3/4">
            {[...Array(4)].map((_, i) => (
              <li>{t(`terms.restrictions.forbidden.${i + 1}`)}</li>
            ))}
          </ul>
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl">
            {t("terms.restrictions.authorization.title")}
          </p>
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl">
            {t("terms.restrictions.data.title")}
          </p>

          <nav id="to-top" className="text-base text-blue-500 lg:text-base">
            <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
          </nav>
          <p
            id="intellectual-property"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("terms.summary.property")}
          </p>
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl">
            {t("terms.property.user.title")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.property.user.1")}
          </p>
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl">
            {t("terms.property.cortexx.title")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.property.cortexx.1")}
          </p>
          <a
            href="https://cortexxmi.com/sumo/user-privacy"
            className="w-full text-base font-bold leading-snug text-blue-500 underline lg:w-3/4 lg:text-base"
          >
            https://cortexxmi.com/sumo/user-privacy
          </a>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.property.cortexx.2")}
          </p>
          <a
            href="https://cortexxmi.com/sumo/user-privacy"
            className="w-full text-base font-bold leading-snug text-blue-500 underline lg:w-3/4 lg:text-base"
          >
            https://cortexxmi.com/sumo/user-privacy
          </a>

          <nav id="to-top" className="text-base text-blue-500 lg:text-base">
            <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
          </nav>
          <p
            id="respect-tcus"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("terms.summary.respect")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.respect.1")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.respect.2")}
          </p>

          <nav id="to-top" className="text-base text-blue-500 lg:text-base">
            <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
          </nav>
          <p
            id="major-force"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("terms.summary.major")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.major.1")}
          </p>

          <nav id="to-top" className="text-base text-blue-500 lg:text-base">
            <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
          </nav>
          <p
            id="misc"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("terms.summary.misc")}
          </p>
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl">
            {t("terms.misc.cession.title")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.misc.cession.1")}
          </p>
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl">
            {t("terms.misc.renonciation.title")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.misc.renonciation.1")}
          </p>
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl">
            {t("terms.misc.independance.title")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.misc.independance.1")}
          </p>
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl">
            {t("terms.misc.divisibility.title")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.misc.divisibility.1")}
          </p>
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl">
            {t("terms.misc.assistance.title")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.misc.assistance.1")}
          </p>
          <p className="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl">
            {t("terms.misc.rights.title")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.misc.rights.1")}&nbsp;
            <a
              href="http://ec.europa.eu/odr"
              className="w-full text-base font-bold leading-snug text-blue-500 underline lg:w-3/4 lg:text-base"
            >
              {t("terms.misc.rights.link")}
            </a>
            &nbsp;{t("terms.misc.rights.2")}
          </p>

          <nav id="to-top" className="text-base text-blue-500 lg:text-base">
            <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
          </nav>
          <p
            id="tcus-time"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("terms.summary.duration")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.duration.1")}
          </p>
          <nav id="to-top" className="text-base text-blue-500 lg:text-base">
            <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
          </nav>
          <p
            id="data-privacy"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("terms.summary.privacy")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.privacy.1")}
          </p>
          <a
            href="https://cortexxmi.com/sumo/user-privacy"
            className="w-full text-base font-bold leading-snug text-blue-500 underline lg:w-3/4 lg:text-base"
          >
            https://cortexxmi.com/sumo/user-privacy
          </a>

          <nav id="to-top" className="text-base text-blue-500 lg:text-base">
            <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
          </nav>
          <p
            id="hosting"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("terms.summary.hosting")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.hosting.1")}
          </p>
          <a
            href="https://cloud.google.com"
            className="w-full text-base font-bold leading-snug text-blue-500 underline lg:w-3/4 lg:text-base lg:text-sm"
          >
            https://cloud.google.com
          </a>

          <nav id="to-top" className="text-base text-blue-500 lg:text-base">
            <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
          </nav>
          <p
            id="general-dispositions"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("terms.summary.dispositions")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("terms.dispositions.1")}
          </p>
        </article>
      </body>
    </div>
  );
};

export default Terms;
