import React from "react";
import GuideBase from "../../components/GuideBase";
import useLanguage from "../../components/hooks";

const listOfLinks = {
  fr: {
    1: "https://www.ameli.fr/paris/assure/sante/themes/activite-physique-sante/age-activite-physique",
    2: "/sumo/guides/feeding/fr",
    3: "https://www.ameli.fr/paris/assure/sante/themes/tabac",
    4: "/sumo/guides/sleep/fr",
  },
  en: {
    1: "https://www.ameli.fr/paris/assure/sante/themes/activite-physique-sante/age-activite-physique",
    2: "/sumo/guides/feeding/en",
    3: "https://www.ameli.fr/paris/assure/sante/themes/tabac",
    4: "/sumo/guides/sleep/en",
  },
};

const PhysicalActivity = () => {
  const [t, links] = useLanguage(listOfLinks);

  return (
    <GuideBase>
      <header>
        <p class="lg:w1/2 my-4 w-full text-3xl font-bold leading-relaxed text-gray-600">
          {t("physical_activity.title")}
        </p>
      </header>
      <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
        {t("physical_activity.intro")}
      </p>
      <nav>
        <p
          id="contents"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("common.summary")}
        </p>

        <ul>
          <li>
            <a
              href="#limitations"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("physical_activity.summary.limitation")}
            </a>
          </li>
          <li>
            <a
              href="#key-advices"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("physical_activity.summary.recuperate")}
            </a>
          </li>
          <li>
            <a
              href="#drink-water"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("physical_activity.summary.drink")}
            </a>
          </li>
          <li>
            <a
              href="#ready"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("physical_activity.summary.ready")}
            </a>
          </li>
          <li>
            <a
              href="#sport"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("physical_activity.summary.sport")}
            </a>
          </li>
          <li>
            <a
              href="#10-advices"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("physical_activity.summary.advices")}
            </a>
          </li>
          <li>
            <a
              href="#sources"
              class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
            >
              {t("common.sources")}
            </a>
          </li>
        </ul>
      </nav>

      <article>
        <h2
          id="limitations"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("physical_activity.summary.limitation")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.limitation.content.1")}
        </p>

        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.limitation.content.2")}
        </p>

        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.limitation.content.3")}
        </p>

        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.limitation.content.4")}
        </p>

        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.limitation.content.5")}
        </p>

        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.limitation.content.6")}
        </p>

        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.limitation.content.7")}
        </p>

        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.limitation.content.8")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="key-advices"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("physical_activity.summary.recuperate")}
        </h2>
        <h3 class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl">
          {t("physical_activity.recuperate.dietetics.title")}
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.recuperate.dietetics.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.recuperate.dietetics.content.2")}
        </p>
        <ul class="mx-8 w-5/6 list-decimal text-base font-bold leading-normal text-gray-500 lg:w-3/4">
          <li>
            <p class="my-4">
              {t("physical_activity.recuperate.dietetics.content.list.1.title")}
              <span class="emoji text-4xl"> 🏃</span>
            </p>
            <p class="font-normal">
              {t(
                "physical_activity.recuperate.dietetics.content.list.1.content"
              )}
            </p>
          </li>
          <li>
            <p class="my-4">
              {t("physical_activity.recuperate.dietetics.content.list.2.title")}
              <span class="emoji text-3xl"> ⏲️</span>
            </p>
            <p class="font-normal">
              {t(
                "physical_activity.recuperate.dietetics.content.list.2.content.1"
              )}
              <a
                href={links[2]}
                class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {[" ", t("physical_activity.links.2"), " "].join("")}
              </a>
              {t(
                "physical_activity.recuperate.dietetics.content.list.2.content.2"
              )}
            </p>
          </li>
          <li>
            <p class="my-4">
              {t("physical_activity.recuperate.dietetics.content.list.3.title")}
              <span class="emoji text-3xl"> 🚭</span>
            </p>
            <p class="font-normal">
              {t(
                "physical_activity.recuperate.dietetics.content.list.3.content.1"
              )}
              <a
                href={links[3]}
                class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {[" ", t("physical_activity.links.3"), " "].join("")}
              </a>
              {t(
                "physical_activity.recuperate.dietetics.content.list.3.content.2"
              )}
            </p>
          </li>
          <li>
            <p class="my-4">
              {t("physical_activity.recuperate.dietetics.content.list.4.title")}
              <span class="emoji text-3xl"> 🛌</span>
            </p>
            <p class="font-normal">
              {t(
                "physical_activity.recuperate.dietetics.content.list.4.content.1"
              )}
              <a
                href={links[4]}
                class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
              >
                {[" ", t("physical_activity.links.4"), " "].join("")}
              </a>
              {t(
                "physical_activity.recuperate.dietetics.content.list.4.content.2"
              )}
            </p>
          </li>
        </ul>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h3 class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl">
          {t("physical_activity.recuperate.rest.title")}
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.recuperate.rest.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.recuperate.rest.content.2")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.recuperate.rest.content.3")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.recuperate.rest.content.4")}
        </p>
        <h3 class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl">
          {t("physical_activity.recuperate.rest_actively.title")}
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.recuperate.rest_actively.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.recuperate.rest_actively.content.2")}
        </p>
        <h3 class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl">
          {t("physical_activity.recuperate.joint.title")}
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.recuperate.joint.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.recuperate.joint.content.2")}
        </p>
        <h3 class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl">
          {t("physical_activity.recuperate.stretch.title")}
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.recuperate.stretch.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.recuperate.stretch.content.2")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.recuperate.stretch.content.3")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="drink-water"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("physical_activity.summary.drink")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.drink.intro.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.drink.intro.2")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.drink.intro.3")}
        </p>
        <h3 class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl">
          {t("physical_activity.drink.digestion.title")}
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.drink.digestion.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.drink.digestion.content.2")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.drink.digestion.content.3")}
        </p>
        <h3 class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl">
          {t("physical_activity.drink.tendinitis.title")}
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.drink.tendinitis.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.drink.tendinitis.content.2")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.drink.tendinitis.content.3")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="ready"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("physical_activity.summary.ready")}
        </h2>
        <h3 class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl">
          {t("physical_activity.ready.injury.title")}
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.ready.injury.content.1")}
        </p>
        <ul class="mx-8 w-5/6 list-disc text-base leading-normal text-gray-500 lg:w-3/4">
          <li>{t("physical_activity.ready.injury.content.list.1")}</li>
          <li>{t("physical_activity.ready.injury.content.list.2")}</li>
          <li>{t("physical_activity.ready.injury.content.list.3")}</li>
          <li>{t("physical_activity.ready.injury.content.list.4")}</li>
        </ul>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.ready.injury.content.2")}
        </p>
        <h3 class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl">
          {t("physical_activity.ready.diesel.title")}
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.ready.diesel.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.ready.diesel.content.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="sport"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("physical_activity.summary.sport")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.sport.intro.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.sport.intro.2")}
        </p>
        <h3 class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl">
          {t("physical_activity.sport.swim.title")}
          <span class="emoji text-3xl"> 🏊‍♂️</span>
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.sport.swim.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.sport.swim.content.2")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.sport.swim.content.3")}
        </p>
        <h3 class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl">
          {t("physical_activity.sport.yoga.title")}
          <span class="emoji text-3xl"> 🧘</span>
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.sport.yoga.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.sport.yoga.content.2")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.sport.yoga.content.3")}
        </p>
        <h3 class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl">
          {t("physical_activity.sport.walk.title")}
          <span class="emoji text-3xl"> 🥾</span>
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.sport.walk.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.sport.walk.content.2")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.sport.walk.content.3")}
        </p>
        <h3 class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl">
          {t("physical_activity.sport.bike.title")}
          <span class="emoji text-3xl"> 🚴</span>
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.sport.bike.content.1")}
        </p>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.sport.bike.content.2")}
        </p>
        <h3 class="my-4 w-full text-xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl">
          {t("physical_activity.sport.trauma.title")}
        </h3>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.sport.trauma.content.1")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="10-advices"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("physical_activity.summary.advices")}
        </h2>
        <ul class="mx-8 w-5/6 list-decimal text-base leading-normal text-gray-500 lg:w-3/4">
          <li class="my-2">{t("physical_activity.advices.list.1")}</li>
          <li class="my-2">{t("physical_activity.advices.list.2")}</li>
          <li class="my-2">{t("physical_activity.advices.list.3")}</li>
          <li class="my-2">{t("physical_activity.advices.list.4")}</li>
          <li class="my-2">{t("physical_activity.advices.list.5")}</li>
          <li class="my-2">{t("physical_activity.advices.list.6")}</li>
          <li class="my-2">{t("physical_activity.advices.list.7")}</li>
          <li class="my-2">{t("physical_activity.advices.list.8")}</li>
          <li class="my-2">{t("physical_activity.advices.list.9")}</li>
          <li class="my-2">{t("physical_activity.advices.list.10")}</li>
        </ul>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
        <h2
          id="sources"
          class="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
        >
          {t("common.sources")}
        </h2>
        <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
          {t("physical_activity.extra_infos.1")}
          <a
            href={links[1]}
            class="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base"
          >
            {[" ", t("physical_activity.links.1"), " "].join("")}
          </a>
          {t("physical_activity.extra_infos.2")}
        </p>
        <p className="my-4 w-full text-base leading-normal text-blue-500 lg:w-3/4 lg:text-base">
          <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
        </p>
      </article>
    </GuideBase>
  );
};

export default PhysicalActivity;
