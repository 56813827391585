import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const Privacy = () => {
  const { t, i18n } = useTranslation(["legal"]);
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang || "en");
  }, [lang, i18n]);

  const getSummaryLink = () => {
    return (
      <nav id="to-top" className="text-base text-blue-500 lg:text-base">
        <a href="#contents">{t("common.summary").toLocaleLowerCase()}</a>
      </nav>
    );
  };

  return (
    <div className="w-full p-5 lg:p-10">
      <body className="relative w-full antialiased">
        <header>
          <p className="lg:w1/2 my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:text-4xl">
            {t("privacy.title")}
          </p>
        </header>

        <nav>
          <p
            id="contents"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("common.summary")}
          </p>

          <ul className="w-full text-base font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base">
            <li>
              <a href="#about">{t("privacy.summary.about")}</a>
            </li>

            <li>
              <a href="#application-field">{t("privacy.summary.field")}</a>
            </li>

            <li>
              <a href="#collection">{t("privacy.summary.collection")}</a>
            </li>

            <li>
              <a href="#goal">{t("privacy.summary.goal")}</a>
            </li>
            <li>
              <a href="#type-of-data">{t("privacy.summary.type")}</a>
            </li>

            <li>
              <a href="#data-storage-duration">
                {t("privacy.summary.duration")}
              </a>
              <ul class="mx-8 w-5/6 list-disc font-bold leading-snug text-blue-500 lg:w-3/4 lg:text-base">
                <li>
                  <a href="#data-storage-duration-1">
                    {t("privacy.summary.duration_1")}
                  </a>
                </li>
                <li>
                  <a href="#data-storage-duration-2">
                    {t("privacy.summary.duration_2")}
                  </a>
                </li>
                <li>
                  <a href="#data-storage-duration-3">
                    {t("privacy.summary.duration_3")}
                  </a>
                </li>
                <li>
                  <a href="#data-storage-duration-4">
                    {t("privacy.summary.duration_4")}
                  </a>
                </li>
              </ul>
            </li>

            <li>
              <a href="#users-rights">{t("privacy.summary.rights")}</a>
            </li>

            <li>
              <a href="#users-obligations">
                {t("privacy.summary.obligations")}
              </a>
            </li>

            <li>
              <a href="#data-destination">{t("privacy.summary.destination")}</a>
            </li>

            <li>
              <a href="#data-transfers">{t("privacy.summary.transfers")}</a>
            </li>

            <li>
              <a href="#links-ext">{t("privacy.summary.links")}</a>
            </li>

            <li>
              <a href="#data-security">{t("privacy.summary.security")}</a>
            </li>

            <li>
              <a href="#confidentiality-version">
                {t("privacy.summary.version")}
              </a>
            </li>

            <li>
              <a href="#contact">{t("privacy.summary.contact")}</a>
            </li>
          </ul>
        </nav>

        <article>
          <h2
            id="about"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("privacy.summary.about")}
          </h2>
          {[...Array(3)].map((_, i) => (
            <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
              {t(`privacy.about.${i + 1}`)}
            </p>
          ))}
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.about.4")}&nbsp;
            <a
              href="mailto:dpo@cortexxmi.com"
              className="w-full text-base font-bold leading-snug text-blue-500 underline lg:w-3/4 lg:text-base lg:text-sm"
            >
              dpo@cortexxmi.com
            </a>
            &nbsp;
            {t("privacy.about.5")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.about.6")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.about.7")}
          </p>
          {getSummaryLink()}
          <h2
            id="application-field"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("privacy.field.title")}
          </h2>
          {[...Array(5)].map((_, i) => (
            <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
              {t(`privacy.field.${i + 1}`)}
            </p>
          ))}
          {getSummaryLink()}
          <h2
            id="collection"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("privacy.collection.title")}
          </h2>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.collection.1")}
          </p>
          <ul class="mx-8 my-4 w-5/6 list-disc text-base leading-relaxed text-gray-500 lg:w-3/4">
            {[...Array(3)].map((_, i) => (
              <li>{t(`privacy.collection.list.${i + 1}`)}</li>
            ))}
          </ul>
          {getSummaryLink()}
          <h2
            id="goal"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("privacy.goal.title")}
          </h2>
          {[...Array(3)].map((_, i) => (
            <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
              {t(`privacy.goal.${i + 1}`)}
            </p>
          ))}
          <ul class="mx-8 my-4 w-5/6 list-disc text-base leading-relaxed text-gray-500 lg:w-3/4">
            {[...Array(3)].map((_, i) => (
              <li>{t(`privacy.goal.list.${i + 1}`)}</li>
            ))}
          </ul>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.goal.4")}
          </p>
          {getSummaryLink()}
          <h2
            id="type-of-data"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("privacy.type.title")}
          </h2>
          <ul class="mx-8 my-4 w-5/6 list-disc text-base leading-relaxed text-gray-500 lg:w-3/4">
            <li>
              <p className="my-4 w-full text-base font-bold leading-normal text-gray-600 lg:w-3/4 lg:text-base">
                {t("privacy.type.1")}
              </p>
            </li>
            <li>
              <p className="my-4 w-full text-base font-bold leading-normal text-gray-600 lg:w-3/4 lg:text-base">
                {t("privacy.type.2")}
              </p>
            </li>
          </ul>
          <hr />
          <p class="my-4 w-full text-base font-bold leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.type.intro.title")}
          </p>
          <p class="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.type.intro.1")}
          </p>
          <hr />
          <p class="my-4 w-full text-base font-bold leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.type.email.title")}
          </p>
          <ul class="mx-8 my-4 w-5/6 list-disc text-base leading-relaxed text-gray-500 lg:w-3/4">
            {[...Array(2)].map((_, i) => (
              <li>{t(`privacy.type.email.list.${i + 1}`)}</li>
            ))}
          </ul>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.type.email.1")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.type.email.2")}&nbsp;
            <a
              href="mailto:dpo@cortexxmi.com"
              className="w-full text-base font-bold leading-snug text-blue-500 underline lg:w-3/4 lg:text-base"
            >
              dpo@cortexxmi.com.
            </a>
          </p>
          <hr />
          <p class="my-4 w-full text-base font-bold leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.type.health.title")}
          </p>
          <ul class="mx-8 my-4 w-5/6 list-disc text-base leading-relaxed text-gray-500 lg:w-3/4">
            {[...Array(2)].map((_, i) => (
              <li>{t(`privacy.type.health.list.${i + 1}`)}</li>
            ))}
          </ul>
          {[...Array(3)].map((_, i) => (
            <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
              {t(`privacy.type.health.${i + 1}`)}
            </p>
          ))}
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.type.health.4")}&nbsp;
            <a
              href="mailto:dpo@cortexxmi.com"
              className="w-full text-base font-bold leading-snug text-blue-500 underline lg:w-3/4 lg:text-base"
            >
              dpo@cortexxmi.com
            </a>
            .
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.type.health.5")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.type.health.6")}
          </p>
          <hr />
          <p class="my-4 w-full text-base font-bold leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.type.surveys.title")}
          </p>
          <ul class="mx-8 my-4 w-5/6 list-disc text-base leading-relaxed text-gray-500 lg:w-3/4">
            {[...Array(2)].map((_, i) => (
              <li>{t(`privacy.type.surveys.list.${i + 1}`)}</li>
            ))}
          </ul>
          <hr />
          <p class="my-4 w-full text-base font-bold leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.type.device.title")}
          </p>
          <ul class="mx-8 my-4 w-5/6 list-disc text-base leading-relaxed text-gray-500 lg:w-3/4">
            {[...Array(2)].map((_, i) => (
              <li>{t(`privacy.type.device.list.${i + 1}`)}</li>
            ))}
          </ul>
          <hr />
          <p class="my-4 w-full text-base font-bold leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.type.logs.title")}
          </p>
          <ul class="mx-8 my-4 w-5/6 list-disc text-base leading-relaxed text-gray-500 lg:w-3/4">
            {[...Array(2)].map((_, i) => (
              <li>{t(`privacy.type.logs.list.${i + 1}`)}</li>
            ))}
          </ul>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.type.logs.1")}
          </p>
          <h2
            id="data-storage-duration"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("privacy.duration.title")}
          </h2>
          <h3
            id="data-storage-duration-1"
            className="my-6 w-full font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl"
          >
            {t("privacy.duration_1.title")}
          </h3>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.duration_1.1")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.duration_1.2")}&nbsp;
            <a
              href="mailto:dpo@cortexxmi.com"
              className="w-full text-base font-bold leading-snug text-blue-500 underline lg:w-3/4 lg:text-base"
            >
              dpo@cortexxmi.com
            </a>
            &nbsp;{t("privacy.duration_1.2")}
          </p>
          <h3
            id="data-storage-duration-2"
            className="my-6 w-full font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl"
          >
            {t("privacy.duration_2.title")}
          </h3>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.duration_2.1")}
          </p>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.duration_2.2")}&nbsp;
            <a
              href="mailto:dpo@cortexxmi.com"
              className="w-full text-base font-bold leading-snug text-blue-500 underline lg:w-3/4 lg:text-base"
            >
              dpo@cortexxmi.com
            </a>
            &nbsp;{t("privacy.duration_2.2")}
          </p>
          <h3
            id="data-storage-duration-3"
            className="my-6 w-full font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl"
          >
            {t("privacy.duration_3.title")}
          </h3>
          {[...Array(3)].map((_, i) => (
            <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
              {t(`privacy.duration_3.${i + 1}`)}
            </p>
          ))}
          <h3
            id="data-storage-duration-4"
            className="my-6 w-full font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-xl"
          >
            {t("privacy.duration_4.title")}
          </h3>
          {[...Array(4)].map((_, i) => (
            <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
              {t(`privacy.duration_4.${i + 1}`)}
            </p>
          ))}
          {getSummaryLink()}
          <h2
            id="users-rights"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("privacy.rights.title")}
          </h2>
          <ul class="mx-8 my-4 w-5/6 list-disc text-base leading-relaxed text-gray-500 lg:w-3/4">
            {[...Array(6)].map((_, i) => (
              <li>{t(`privacy.rights.list.${i + 1}`)}</li>
            ))}
            <li>
              {t(`privacy.rights.list.7`)}&nbsp;
              <a
                href="mailto:dpo@cortexxmi.com"
                className="w-full text-base font-bold leading-snug text-blue-500 underline lg:w-3/4 lg:text-base"
              >
                dpo@cortexxmi.com
              </a>
              .
            </li>
            {[...Array(3)].map((_, i) => (
              <li>{t(`privacy.rights.list.${i + 8}`)}</li>
            ))}
            <li>
              {t(`privacy.rights.list.11`)}&nbsp;
              <a
                href="mailto:dpo@cortexxmi.com"
                className="w-full text-base font-bold leading-snug text-blue-500 underline lg:w-3/4 lg:text-base"
              >
                dpo@cortexxmi.com
              </a>
              &nbsp;{t(`privacy.rights.list.12`)}
            </li>
          </ul>
          {getSummaryLink()}
          <h2
            id="users-obligations"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("privacy.obligations.title")}
          </h2>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.obligations.1")}
          </p>
          {getSummaryLink()}
          <h2
            id="data-destination"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("privacy.destination.title")}
          </h2>
          {[...Array(9)].map((_, i) => (
            <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
              {t(`privacy.destination.${i + 1}`)}
            </p>
          ))}
          {getSummaryLink()}
          <h2
            id="data-transfers"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("privacy.transfers.title")}
          </h2>
          {[...Array(3)].map((_, i) => (
            <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
              {t(`privacy.transfers.${i + 1}`)}
            </p>
          ))}
          <a
            href="https://www.cnil.fr/fr/les-clauses-contractuelles-types-de-la-commision-europeenne"
            className="my-8 w-full text-base font-bold leading-relaxed text-blue-500 underline lg:w-3/4 lg:text-base"
          >
            https://www.cnil.fr/fr/les-clauses-contractuelles-types-de-la-commision-europeenne
          </a>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base" />
          {getSummaryLink()}
          <h2
            id="links-ext"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("privacy.links.title")}
          </h2>
          {[...Array(3)].map((_, i) => (
            <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
              {t(`privacy.links.${i + 1}`)}
            </p>
          ))}
          {getSummaryLink()}
          <h2
            id="data-security"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("privacy.security.title")}
          </h2>
          {[...Array(2)].map((_, i) => (
            <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
              {t(`privacy.security.${i + 1}`)}
            </p>
          ))}
          {getSummaryLink()}
          <h2
            id="confidentiality-version"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("privacy.version.title")}
          </h2>
          {[...Array(2)].map((_, i) => (
            <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
              {t(`privacy.version.${i + 1}`)}
            </p>
          ))}
          {getSummaryLink()}
          <h2
            id="contact"
            className="my-4 w-full text-2xl font-bold leading-relaxed text-gray-600 lg:w-3/4 lg:text-2xl"
          >
            {t("privacy.contact.title")}
          </h2>
          <p className="my-4 w-full text-base leading-normal text-gray-500 lg:w-3/4 lg:text-base">
            {t("privacy.contact.1")}&nbsp;
            <a
              href="mailto:dpo@cortexxmi.com"
              className="w-full text-base font-bold leading-snug text-blue-500 underline lg:w-3/4 lg:text-base"
            >
              dpo@cortexxmi.com
            </a>
            &nbsp;{t("privacy.contact.1")}
          </p>
          {getSummaryLink()}
        </article>

        <script src="{{ asset('css/app.js') }}" />
      </body>
    </div>
  );
};

export default Privacy;
