import React from "react";
import { useTranslation } from "react-i18next";

const Technology = () => {
  const { t } = useTranslation();

  return (
    <section id="technology" className="body-font bg-white text-gray-700">
      <div className="container mx-auto flex items-center justify-center px-5 pt-20">
        <div className="mb-10 flex w-full flex-col">
          <div className="mb-10">
            <h1 class="text-4xl font-extrabold tracking-tight text-cortexx-900 md:text-5xl">
              {t("technology.title")}
            </h1>
          </div>
          <div className="flex flex-col text-justify  md:flex-row">
            <div className="my-4 h-auto leading-relaxed md:mr-20 md:w-1/3 ">
              <h2 className="title-font mb-8 text-2xl font-semibold text-gray-600">
                {t("technology.ai.title")}
              </h2>
              <p className="mb-8 text-gray-500">{t("technology.ai.line_1")}</p>
              <p className="text-gray-500">{t("technology.ai.line_2")}</p>
            </div>
            <div className="my-4 h-auto leading-relaxed md:mr-20 md:w-1/3 ">
              <h2 className="title-font mb-8 text-2xl font-semibold text-gray-600">
                {t("technology.rpm.title")}
              </h2>
              <p className="mb-8 text-gray-500">{t("technology.rpm.line_1")}</p>
              <p className="text-gray-500">{t("technology.rpm.line_2")}</p>
            </div>
            <div className="my-4 h-auto leading-relaxed md:w-1/3 ">
              <h2 className="title-font mb-8 text-2xl font-semibold text-gray-600">
                {t("technology.dtx.title")}
              </h2>
              <p className="mb-8 text-gray-500">{t("technology.dtx.line_1")}</p>
              <p className="text-gray-500">{t("technology.dtx.line_2")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Technology;
